import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import forEach from "lodash/forEach"
import className from "classnames"
import {tt} from "@lib/helpers"

export const AdminEventsNewTab = ({event_path, t}) => {
  const [__indexTab, __indexTabSet] = React.useState(0)

  return (
    <div className="AdminEventsNew__tabs">
      {[
        {
          label: tt(t, "agency_page"),
        },
        {
          label: tt(t, "talents_list"),
        },
      ].map((x, i) => (
        <a
          className={className("AdminEventsNew__tabs__a", {
            "AdminEventsNew__tabs__a--active": i == __indexTab,
          })}
          key={i}
          onClick={() => {
            __indexTabSet(i)

            forEach(
              document.querySelectorAll(`.AdminEventsNew__tab`),
              ($tab, j) => {
                $tab.classList.remove("AdminEventsNew__tab--active")

                if (i == j) {
                  $tab.classList.add("AdminEventsNew__tab--active")
                }
              }
            )
          }}
        >
          {get(x, "label")}
        </a>
      ))}

      {event_path && (
        <a
          className="Button Button--white-purple Button--small"
          href={event_path}
        >
          プレビュー
        </a>
      )}
    </div>
  )
}

export default AdminEventsNewTab
