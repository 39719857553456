import React from "react"
import moment from "moment-timezone"

import {tt} from "@lib/helpers"

type PropTypes = {
  $$startAt: moment.Moment
  $$endAt: moment.Moment
  current_time?: string | null
  hideAfterStart?: boolean
  t: RailsI18n.T
}

const Countdown: React.FC<PropTypes> = ({
  $$startAt,
  $$endAt,
  current_time = null,
  hideAfterStart = false,
  t,
}) => {
  const [__countdownText, __countdownTextSet] = React.useState("")
  const ___isUnMounted = React.useRef(false)

  // We need to keep this in a ref, given we only need to compare
  // the current time of the browser and the server only once
  // at the first time the component is rendered.
  const ___currentTimeDiff = React.useRef(moment(current_time).diff(moment()))

  const getCurrentTime = () =>
    moment().add(___currentTimeDiff.current, "milliseconds")

  const getDiffString = ($$diffDuration: moment.Duration) => {
    if ($$diffDuration.as("months") >= 1) {
      return $$diffDuration.humanize()
    }

    if ($$diffDuration.as("days") >= 1) {
      return `${moment
        .duration(
          moment($$startAt).startOf("day").diff(getCurrentTime().startOf("day"))
        )
        .days()}${tt(t, "day")}`
    }

    if ($$diffDuration.as("hours") >= 1) {
      return `${$$diffDuration.hours()}${tt(
        t,
        "hour"
      )} ${$$diffDuration.minutes()}${tt(
        t,
        "minute"
      )} ${$$diffDuration.seconds()}${tt(t, "second")}`
    }

    if ($$diffDuration.as("minutes") >= 1) {
      return `${$$diffDuration.minutes()}${tt(
        t,
        "minute"
      )} ${$$diffDuration.seconds()}${tt(t, "second")}`
    }

    return `${$$diffDuration.seconds()}${tt(t, "second")}`
  }

  const setCountdown = () => {
    const _$$moment = getCurrentTime()

    if (!___isUnMounted.current) {
      if (_$$moment.isBefore($$startAt)) {
        const $$diffDuration = moment.duration($$startAt.diff(_$$moment))
        __countdownTextSet(
          `${tt(t, "start_in")} ${getDiffString($$diffDuration)}`
        )
      } else if (_$$moment.isBetween($$startAt, $$endAt)) {
        const $$diffDuration = moment.duration($$endAt.diff(_$$moment))
        __countdownTextSet(
          `${tt(t, "end_in")} ${getDiffString($$diffDuration)}`
        )
      } else {
        __countdownTextSet(tt(t, "ended"))
        return
      }

      window.setTimeout(() => {
        setCountdown()
      }, 1000)
    }
  }

  React.useEffect(() => {
    setCountdown()

    return () => {
      ___isUnMounted.current = true
    }
  }, [])

  if (hideAfterStart) {
    if (getCurrentTime().isAfter($$startAt)) {
      return null
    }
  }

  return <p className="AdminSpotsShow__countdown">{__countdownText}</p>
}

export default Countdown
