import React from "react"
import "agora-rtc-sdk-ng"
import moment from "moment-timezone"
import map from "lodash/map"
import get from "lodash/get"
import className from "classnames"

import {tt} from "@lib/helpers"

import Countdown from "@components/molecules/Countdown"

type AdminSpotsShowProps = {
  data: {
    spotId: string
    spotToken: string
    appId: string
    channelKey: string
    token: string
    uid: string
    publisherUid: string
    userUid: string
    startAt: string
    seconds: string
    current_time: string
  }
  muteOnStart: boolean
  t: RailsI18n.T
}

type AdminSpotsShowState = {
  __streams: {
    [key: string]: {
      _id: string
      _remoteUser: any
    }
  }
  isMuteVideoPartner: boolean
}

class AdminSpotsShow extends React.Component<
  AdminSpotsShowProps,
  AdminSpotsShowState
> {
  ___client = AgoraRTC.createClient({mode: "live", codec: "h264"})

  ___$$startAt = moment(this.props.data.startAt).subtract(10, "seconds")
  ___$$endAt = moment(this.props.data.startAt).add(
    this.props.data?.seconds,
    "seconds"
  )

  ___$videos = React.createRef<HTMLDivElement>()

  state: AdminSpotsShowState = {
    __streams: {},
    isMuteVideoPartner: this.props.muteOnStart,
  }

  getVideoId = (streamId: string) => {
    return `video-${this.props.data.spotId}-${streamId}`
  }

  getAudioId = (streamId: string) => {
    return `audio-${this.props.data.spotId}-${streamId}`
  }

  leave = () => {
    this.setState(
      {
        __streams: {},
      },
      () => {
        this.___client.leave()

        const _$videos = this.___$videos.current as HTMLDivElement

        if (_$videos) {
          _$videos.innerHTML = ""
        }
      }
    )
  }

  componentDidMount = async () => {
    if (moment(this.props?.data?.current_time).isAfter(this.___$$endAt)) {
      return
    }

    const {appId, channelKey, token, uid} = get(this.props, "data")
    window.setTimeout(async () => {
      try {
        const uid1 = await this.___client.join(
          appId,
          channelKey,
          token,
          Number(uid)
        )
        console.log("join success")
      } catch (e) {
        console.log("join failed", e)
      }
    }, moment(this.___$$startAt).diff(moment(this.props?.data?.current_time)))

    window.setTimeout(() => {
      this.leave()
    }, moment(this.___$$endAt).diff(moment(this.props?.data?.current_time)))

    this.___client.on("user-joined", async (remoteUser) => {
      console.log("user joined successfully: " + remoteUser.uid)
      const _videoId = this.getVideoId(remoteUser.uid)
      const $video = document.createElement("div")
      $video.setAttribute("id", _videoId)
      $video.setAttribute("class", "AdminSpotsShow__video")
      $video.setAttribute("style", `order: ${remoteUser.uid}`) // The order of appended videos and items in this.state may not match naturally, so we need to sort by their IDs.

      if (!document.getElementById(_videoId)) {
        this.___$videos.current?.appendChild($video)
      }
      this.setState((prevState) => ({
        __streams: {
          ...prevState?.__streams,
          [remoteUser.uid]: {
            _id: remoteUser.uid,
            _remoteUser: remoteUser,
          },
        },
      }))
    })
    this.___client.on("user-published", async (remoteUser, mediaType) => {
      const stream = await this.___client.subscribe(remoteUser, mediaType)

      if (stream) {
        console.log("subscribe video success")

        const _videoId = this.getVideoId(stream._uintId)
        if (mediaType == "video") {
          remoteUser._videoTrack.play(_videoId)
        } else if (mediaType == "audio") {
          const _audioId = this.getAudioId(stream._uintId)
          remoteUser._audioTrack.stop()
        }
      }
    })

    this.___client.on("user-unpublished", async (remoteUser, mediaType) => {
      if (mediaType == "video") {
        await this.___client.unpublish(remoteUser._videoTrack)
      } else if (mediaType == "audio") {
        await this.___client.unpublish(remoteUser._audioTrack)
      }
    })

    this.___client.on("user-info-updated", async (uid, msg) => {
      const _$video = document.getElementById(this.getVideoId(uid))
      if (msg === "mute-video") {
        this.setState({
          isMuteVideoPartner: true,
        })
        if (_$video) {
          window.setTimeout(() => {
            const _$videoOverlay = document.createElement("div")
            _$videoOverlay.setAttribute(
              "class",
              "AdminSpotsShow__video__overlay"
            )

            _$video.appendChild(_$videoOverlay)
          }, 1000)
        }
      } else if (msg === "unmute-video") {
        this.setState({
          isMuteVideoPartner: false,
        })
        if (_$video) {
          const _$videoOverlay = _$video.querySelector(
            ".AdminSpotsShow__video__overlay"
          )
          _$videoOverlay?.remove()
        }
      }
    })
  }

  render = () => {
    const {t} = this.props

    return (
      <div className="AdminSpotsShow__data">
        <div className="AdminSpotsShow__videos" ref={this.___$videos}></div>

        <div className="AdminSpotsShow__infos">
          {map(this.state.__streams, (x, i) => {
            const _streamState = x

            if (!x?._id) {
              return null
            }

            return (
              <div
                className="AdminSpotsShow__info"
                key={i}
                style={{
                  order: Number(x._id), // Same order problem.
                }}
              >
                {_streamState && (
                  <div className="Buttons">
                    <a
                      className={className("Button", {
                        "Button--small": true,
                        "Button--white": true,
                      })}
                      onClick={() => {
                        const _remoteUser = _streamState._remoteUser

                        _remoteUser._audioTrack.stop()
                      }}
                    >
                      {tt(t, "mute")}
                    </a>

                    <a
                      className={className("Button", {
                        "Button--small": true,
                      })}
                      onClick={() => {
                        const _remoteUser = _streamState._remoteUser

                        _remoteUser._audioTrack.play()
                      }}
                    >
                      {tt(t, "unmute")}
                    </a>
                  </div>
                )}
              </div>
            )
          })}
        </div>

        <Countdown
          $$startAt={moment(this.props.data?.startAt)}
          $$endAt={this.___$$endAt}
          current_time={this.props?.data?.current_time}
          hideAfterStart={false}
          t={get(t, "countdown")}
        />
      </div>
    )
  }
}

export default AdminSpotsShow
