import * as React from "react"
import get from "lodash/get"
import filter from "lodash/filter"
import find from "lodash/find"
import className from "classnames"
import "agora-rtc-sdk-ng"
import moment from "moment-timezone"
import Cookies from "js-cookie"

import {
  axios,
  VIDEO_DEVICE_ID_KEY,
  AUDIO_DEVICE_ID_KEY,
  VIDEO_FLIP_X_KEY,
  AUDIO_MUTE_KEY,
  VIDEO_MUTE_KEY,
  // beautyEffectOptions,
} from "@lib/helpers"

import Countdown from "@components/molecules/Countdown"
import DialCountdown from "@components/molecules/DialCountdown"

const START_AT_OFFSET = 3 // We need offset seconds here to let webcams initiate before the call.

type Props = {
  data: {
    appId: string
    channelKey: string
    token: string
    uid: number
    startAt: string
    seconds: number
    is_purchaser: boolean
    me: {
      name: string
      icon_image_url_with_fallback: string
    }
    partner: {
      name: string
      icon_image_url_with_fallback: string
    }
    user: {
      name: string
      description: string
      past_bookings: any[]
      last_screenshot: any
    }
    booking_path: string
    check_availability_booking_path: string
    talk_log_booking_path: string
    after_booking_path: string
    cloud_recordings_path: string
    spot_id: number
    version: string
    package: {
      [key: string]: boolean
    }
    t: {
      [key: string]: any
    }
    current_time: string
  }
}

type State = {
  calling: boolean
  callEnded: boolean
  overlayed: boolean
  selfVideo: any | null
  selfAudio: any | null
  partnerVideo: any | null
  partnerAudio: any | null
  audioinputs: MediaDeviceInfo[]
  audioinputCurrent: string
  videoinputs: MediaDeviceInfo[]
  videoinputCurrent: string
  isActivePanel: boolean
  isActivePanelAudio: boolean
  isActivePanelVideo: boolean
  isActivePanelMemo: boolean
  isMuteAudio: boolean
  isMuteVideoSelf: boolean
  isMuteVideoPartner: boolean
  isFlippedX: boolean
  videoAreaSelf: string
  videoAreaPartner: string
  volume: number
  [key: string]: any
}

class AppBookingsTalk extends React.Component<Props, State> {
  static propTypes = {}

  static defaultProps = {}

  client = AgoraRTC.createClient({mode: "live", codec: "h264"})

  $$startAt = moment(get(this.props, "data.startAt")).subtract(
    get(this.props, "data.is_purchaser")
      ? 5 + START_AT_OFFSET
      : START_AT_OFFSET,
    "seconds"
  )
  $$endAt = moment(get(this.props, "data.startAt")).add(
    get(this.props, "data.seconds", 0) +
      (this.props.data?.is_purchaser ? 0 : 2),
    "seconds"
  )

  state = {
    calling: false,
    callEnded: false,
    overlayed: true,
    selfVideo: null,
    selfAudio: null,
    partnerVideo: null,
    partnerAudio: null,
    audioinputs: [],
    audioinputCurrent: Cookies.get(AUDIO_DEVICE_ID_KEY)
      ? (Cookies.get(AUDIO_DEVICE_ID_KEY) as string)
      : "",
    videoinputs: [],
    videoinputCurrent: Cookies.get(VIDEO_DEVICE_ID_KEY)
      ? (Cookies.get(VIDEO_DEVICE_ID_KEY) as string)
      : "",
    isActivePanel: false,
    isActivePanelAudio: false,
    isActivePanelVideo: false,
    isActivePanelMemo: false,
    isMuteAudio: Cookies.get(AUDIO_MUTE_KEY)
      ? Boolean(Cookies.get(AUDIO_MUTE_KEY))
      : false,
    isMuteVideoSelf: Cookies.get(VIDEO_MUTE_KEY)
      ? Boolean(Cookies.get(VIDEO_MUTE_KEY))
      : false,
    isMuteVideoPartner: false,
    isFlippedX: Cookies.get(VIDEO_FLIP_X_KEY) ? true : false,
    videoAreaSelf: "videoAreaFront",
    videoAreaPartner: "videoAreaBack",
    volume: 100,
  }

  playSelfStream = async () => {
    try {
      const mediaStream = await window.navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: get(this.state, "audioinputCurrent")
            ? {
                exact: get(this.state, "audioinputCurrent"),
              }
            : undefined,
        },
        video: {
          deviceId: get(this.state, "videoinputCurrent")
            ? ({
                exact: get(this.state, "videoinputCurrent"),
              } as ConstrainDOMString)
            : undefined,
        },
      })

      const audioMediaStreamTrack = mediaStream.getAudioTracks()[0]
      const videoMediaStreamTrack = mediaStream.getVideoTracks()[0]
      let localAudio = null
      let localVideo = null
      if (videoMediaStreamTrack.label.startsWith("OBS")) {
        localAudio = await AgoraRTC.createCustomAudioTrack({
          mediaStreamTrack: audioMediaStreamTrack,
        })
        localVideo = await AgoraRTC.createCustomVideoTrack({
          mediaStreamTrack: videoMediaStreamTrack,
        })
      } else {
        localAudio = await AgoraRTC.createMicrophoneAudioTrack()
        localVideo = await AgoraRTC.createCameraVideoTrack()
      }
      this.setState(
        {selfVideo: localVideo, selfAudio: localAudio},
        async () => {
          document.getElementById(get(this.state, "videoAreaSelf")).innerHTML =
            ""
          localVideo.play(get(this.state, "videoAreaSelf"), {fit: "cover"})
          await this.client.setClientRole("host")
          await this.client.publish([localAudio, localVideo])

          if (
            get(this.state, "isMuteVideoSelf") ||
            !get(this.props, "data.package.video_usable")
          ) {
            localVideo.setMuted(true)
          }

          if (get(this.state, "isMuteAudio")) {
            localAudio.setMuted(true)
          }
        }
      )
    } catch (error) {
      if (
        Cookies.get(AUDIO_DEVICE_ID_KEY) ||
        Cookies.get(VIDEO_DEVICE_ID_KEY)
      ) {
        // A camera or microphone failed to start.
        // It can be because the device is unavailable or removed.
        // We then make an attempt to reset to default device ids.
        Cookies.remove(AUDIO_DEVICE_ID_KEY)
        Cookies.remove(VIDEO_DEVICE_ID_KEY)
        window.location.reload()
      }
    }
  }

  timerCheckAvailability = async () => {
    const response = await axios.get(
      get(this.props, "data.check_availability_booking_path")
    )

    if (!get(response, "data.spot.available")) {
      window.location.reload()
      return
    }

    if (!get(this.state, "callEnded")) {
      window.setTimeout(this.timerCheckAvailability, 2000)
    }
  }

  startCall = () => {
    this.setState(
      {
        calling: true,
      },
      async () => {
        this.timerCheckAvailability()

        const {appId, channelKey, token, uid} = get(this.props, "data")
        try {
          const uid1 = await this.client.join(
            appId,
            channelKey,
            token,
            Number(uid)
          )
          console.log("join success")
          axios.post(get(this.props, "data.talk_log_booking_path"), {
            spot_id: get(this.props, "data.spot_id"),
            category: "call start",
            description: `App Version: ${get(
              this.props,
              "data.version"
            )} \nDevice: ${get(
              window,
              "navigator.userAgent"
            )} \nStart at: ${moment(
              get(this.props, "data.startAt")
            ).toString()} \nEnd at: ${this.$$endAt.toString()} \nSeconds: ${get(
              this.props,
              "data.seconds",
              0
            )}`,
          })

          this.playSelfStream()
        } catch (e) {
          console.log("join failed", e)
        }
      }
    )
  }

  leave = () => {
    this.client.leave()

    this.setState(
      {
        calling: false,
        callEnded: true,
      },
      async () => {
        try {
          await axios.post(get(this.props, "data.talk_log_booking_path"), {
            spot_id: get(this.props, "data.spot_id"),
            category: "call end",
          })
        } finally {
          if (!get(this.props, "data.next_booking")) {
            window.location.href = get(this.props, "data.after_booking_path")
          }
        }
      }
    )
  }

  networkQualityLog = () => {
    let networkQualityCount = 0

    // Reports the network quality of the local user once every two seconds.
    // https://docs.agora.io/en/Video/API%20Reference/web/interfaces/agorartc.client.html#on
    this.client.on("network-quality", (stats: any) => {
      if (networkQualityCount >= 9) {
        networkQualityCount = 0

        axios.post(get(this.props, "data.talk_log_booking_path"), {
          spot_id: get(this.props, "data.spot_id"),
          category: "network quality",
          description: `${get(stats, "uplinkNetworkQuality")},${get(
            stats,
            "downlinkNetworkQuality"
          )}`,
        })
      }

      networkQualityCount += 1
    })
  }

  componentDidMount = async () => {
    if (
      !get(this.props, "data.is_purchaser") // A user may not use a browser, so let a publisher call the API.
    ) {
      axios.post(this.props.data?.cloud_recordings_path)
    }

    try {
      // Safari requires `navigator.mediaDevices.getUserMedia` too be called once with default credentials
      // in order for `navigator.mediaDevices.enumerateDevices` to work.
      //
      // https://github.com/webrtcHacks/WebRTC-Camera-Resolution/blob/master/js/resolutionScan.js
      // https://github.com/samdutton/simpl/blob/gh-pages/getusermedia/sources/js/main.js
      // https://docs.agora.io/en/Video/custom_video_web?platform=Web
      await window.navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      })
    } finally {
      // While a user has another app accessing a camera, i.e. they use FaceRig,
      // `getUserMedia()` will throw a DOMException error.
      // But it doesn't matter, it's safe to proceed to `enumerateDevices()` anyway.
      const mediaDevices =
        await window.navigator.mediaDevices.enumerateDevices()

      const videoinputs = filter(
        mediaDevices,
        (x) => get(x, "kind") == "videoinput"
      )

      const audioinputs = filter(
        mediaDevices,
        (x) => get(x, "kind") == "audioinput"
      )

      this.setState(
        (prevState) => ({
          videoinputs,
          videoinputCurrent: get(prevState, "videoinputCurrent")
            ? get(prevState, "videoinputCurrent")
            : get(videoinputs, "0.deviceId"),
          audioinputs,
          audioinputCurrent: get(prevState, "audioinputCurrent")
            ? get(prevState, "audioinputCurrent")
            : get(audioinputs, "0.deviceId"),
        }),
        () => {
          window.setTimeout(
            this.startCall,
            Math.max(
              moment(this.$$startAt).diff(
                moment(this.props?.data?.current_time)
              ),
              0
            )
          )

          window.setTimeout(
            this.leave,
            Math.max(
              moment(this.$$endAt).diff(moment(this.props?.data?.current_time)),
              0
            )
          )

          this.client.on("user-published", async (remoteUser, mediaType) => {
            const stream = await this.client.subscribe(remoteUser, mediaType)
            if (mediaType == "video") {
              console.log("subscribe video success")
              remoteUser.videoTrack.play(get(this.state, "videoAreaPartner"))
            }
            if (mediaType == "audio") {
              console.log("subscribe audio success")
              remoteUser.audioTrack.play()
            }
            this.setState({
              partnerVideo: remoteUser.videoTrack,
              partnerAudio: remoteUser.audioTrack,
            })
          })

          this.client.on("user-unpublished", async (remoteUser, mediaType) => {
            const stream = await this.client.unsubscribe(remoteUser, mediaType)
            if (stream) {
              stream.stop()
            }
          })

          this.networkQualityLog()

          this.client.on("user-info-updated", async (uid, msg) => {
            if (msg === "mute-video") {
              this.setState({
                isMuteVideoPartner: true,
              })
            }
            if (msg === "unmute-video") {
              this.setState({
                isMuteVideoPartner: false,
              })
            }
          })
        }
      )
    }
  }

  render = () => {
    return (
      <FullHeight className="AppBookingsTalk">
        <div className="kontainer-app">
          <div className="AppBookingsTalk__wrapper">
            <div
              id="videoAreaBack"
              className={className("AppBookingsTalk__wrapper__video-area", {
                "AppBookingsTalk__wrapper__video-area--flipped-x":
                  get(this.state, "isFlippedX") &&
                  get(this.state, "videoAreaSelf") == "videoAreaBack",
              })}
            ></div>

            <div className="AppBookingsTalk__wrapper__shadow"></div>

            {(() => {
              let backgroundImage: string | null | undefined

              if (
                get(this.state, "videoAreaSelf") == "videoAreaBack" &&
                (get(this.state, "isMuteVideoSelf") ||
                  !get(this.props, "data.package.video_usable"))
              ) {
                backgroundImage = `${get(
                  this.props,
                  "data.me.icon_image_url_with_fallback"
                )}`
              }

              console.log(get(this.state, "partnerVideo"))
              if (
                get(this.state, "videoAreaPartner") == "videoAreaBack" &&
                (get(this.state, "partnerVideo") ||
                  get(this.state, "partnerAudio")) &&
                (get(this.state, "isMuteVideoPartner") ||
                  !get(this.props, "data.package.partner_video_usable"))
              ) {
                backgroundImage = `${get(
                  this.props,
                  "data.partner.icon_image_url_with_fallback"
                )}`
              }

              if (!backgroundImage) {
                return null
              }

              return (
                <div className="AppBookingsTalk__wrapper__placeholder">
                  <img
                    className="AppBookingsTalk__wrapper__placeholder__img"
                    src={backgroundImage}
                  />
                </div>
              )
            })()}

            {!get(this.state, "partnerVideo") &&
              !get(this.state, "partnerAudio") &&
              get(this.state, "videoAreaPartner") == "videoAreaBack" && (
                <p className="AppBookingsTalk__wrapper__waiting">
                  {get(this.props, "data.t.current.waiting_partner")}
                </p>
              )}

            <div className="AppBookingsTalk__top">
              <div className="AppBookingsTalk__top__partner">
                <a
                  className={className("AppBookingsTalk__top__partner__a", {
                    "AppBookingsTalk__top__partner__a--cur-d": get(
                      this.props,
                      "data.is_purchaser"
                    ),
                  })}
                  onClick={() => {
                    if (!get(this.props, "data.is_purchaser")) {
                      this.setState({
                        isActivePanelMemo: true,
                      })
                    }
                  }}
                >
                  <img
                    className="AppBookingsTalk__top__partner__img"
                    src={get(
                      this.props,
                      "data.partner.icon_image_url_with_fallback"
                    )}
                  />

                  <span className="AppBookingsTalk__top__partner__span">
                    {get(this.props, "data.partner.name")}
                  </span>
                </a>
              </div>

              <Countdown
                $$startAt={this.$$startAt}
                $$endAt={this.$$endAt}
                current_time={this.props?.data?.current_time}
                hideAfterStart
                t={get(this.props, "data.t.current.countdown")}
              />

              <DialCountdown
                $$startAt={this.$$startAt}
                current_time={this.props?.data?.current_time}
                $$endAt={this.$$endAt}
              />
            </div>

            {get(this.state, "calling") && (
              <React.Fragment>
                <div className="AppBookingsTalk__bottom">
                  <div className="AppBookingsTalk__bottom__self">
                    <div
                      id="videoAreaFront"
                      className={className(
                        "AppBookingsTalk__bottom__self__video-area",
                        {
                          "AppBookingsTalk__bottom__self__video-area--flipped-x":
                            get(this.state, "isFlippedX") &&
                            get(this.state, "videoAreaSelf") ==
                              "videoAreaFront",
                        }
                      )}
                    ></div>

                    {(() => {
                      let backgroundImage = "none"

                      if (
                        get(this.state, "videoAreaSelf") == "videoAreaFront" &&
                        (get(this.state, "isMuteVideoSelf") ||
                          !get(this.props, "data.package.video_usable"))
                      ) {
                        backgroundImage = `url(${get(
                          this.props,
                          "data.me.icon_image_url_with_fallback"
                        )})`
                      }

                      if (
                        get(this.state, "videoAreaPartner") ==
                          "videoAreaFront" &&
                        get(this.state, "partnerVideo") &&
                        (get(this.state, "isMuteVideoPartner") ||
                          !get(this.props, "data.package.partner_video_usable"))
                      ) {
                        backgroundImage = `url(${get(
                          this.props,
                          "data.partner.icon_image_url_with_fallback"
                        )})`
                      }

                      return (
                        <div
                          className={className(
                            "AppBookingsTalk__bottom__self__placeholder",
                            {
                              "AppBookingsTalk__bottom__self__placeholder--active":
                                backgroundImage != "none",
                            }
                          )}
                          style={{
                            /* stylelint-disable */
                            backgroundImage,
                          }}
                        ></div>
                      )
                    })()}

                    {!get(this.state, "partnerVideo") &&
                      get(this.state, "videoAreaPartner") ==
                        "videoAreaFront" && (
                        <p className="AppBookingsTalk__bottom__self__waiting">
                          {get(this.props, "data.t.current.waiting_partner")}
                        </p>
                      )}
                  </div>

                  <div className="AppBookingsTalk__bottom__buttons">
                    <a
                      className={className(
                        "AppBookingsTalk__bottom__buttons__a",
                        {
                          "AppBookingsTalk__bottom__buttons__a--swap": true,
                          "AppBookingsTalk__bottom__buttons__a--active":
                            get(this.state, "videoAreaSelf") == "videoAreaBack",
                        }
                      )}
                      onClick={() => {
                        const selfVideo = get(this.state, "selfVideo") as any

                        if (selfVideo) {
                          selfVideo.stop()
                        }

                        const partnerVideo = get(
                          this.state,
                          "partnerVideo"
                        ) as any

                        if (partnerVideo) {
                          partnerVideo.stop()
                        }

                        const _videoAreaSelf = get(this.state, "videoAreaSelf")
                        const _videoAreaPartner = get(
                          this.state,
                          "videoAreaPartner"
                        )

                        this.setState(
                          {
                            videoAreaSelf: _videoAreaPartner,
                            videoAreaPartner: _videoAreaSelf,
                          },
                          () => {
                            if (selfVideo) {
                              selfVideo.play(get(this.state, "videoAreaSelf"))
                            }

                            if (partnerVideo) {
                              partnerVideo.play(
                                get(this.state, "videoAreaPartner")
                              )
                            }
                          }
                        )
                      }}
                    />

                    <a
                      className={className(
                        "AppBookingsTalk__bottom__buttons__a",
                        {
                          "AppBookingsTalk__bottom__buttons__a--audio": true,
                          "AppBookingsTalk__bottom__buttons__a--active": get(
                            this.state,
                            "isMuteAudio"
                          ),
                        }
                      )}
                      onClick={() => {
                        axios
                          .post(get(this.props, "data.talk_log_booking_path"), {
                            spot_id: get(this.props, "data.spot_id"),
                            category: "audio settings",
                            description: !get(this.state, "isMuteAudio")
                              ? "mute"
                              : "unmute",
                          })
                          .then(() => {
                            this.setState(
                              (prevState) => ({
                                isMuteAudio: !prevState.isMuteAudio,
                              }),
                              () => {
                                const selfAudio = get(
                                  this.state,
                                  "selfAudio"
                                ) as any

                                if (selfAudio) {
                                  if (get(this.state, "isMuteAudio")) {
                                    Cookies.set(AUDIO_MUTE_KEY, "1")
                                    selfAudio.setMuted(true)
                                  } else {
                                    Cookies.remove(AUDIO_MUTE_KEY)
                                    selfAudio.setMuted(false)
                                  }
                                }
                              }
                            )
                          })
                      }}
                    />

                    <a
                      className={className(
                        "AppBookingsTalk__bottom__buttons__a",
                        {
                          "AppBookingsTalk__bottom__buttons__a--video": true,
                          "AppBookingsTalk__bottom__buttons__a--active": get(
                            this.state,
                            "isMuteVideoSelf"
                          ),
                          "AppBookingsTalk__bottom__buttons__a--disabled": !get(
                            this.props,
                            "data.package.video_usable"
                          ),
                        }
                      )}
                      onClick={() => {
                        if (get(this.props, "data.package.video_usable")) {
                          this.setState(
                            (prevState) => ({
                              isMuteVideoSelf: !prevState.isMuteVideoSelf,
                            }),
                            () => {
                              const selfVideo = get(
                                this.state,
                                "selfVideo"
                              ) as any

                              if (selfVideo) {
                                if (get(this.state, "isMuteVideoSelf")) {
                                  Cookies.set(VIDEO_MUTE_KEY, "1")
                                  selfVideo.setMuted(true)
                                } else {
                                  Cookies.remove(VIDEO_MUTE_KEY)
                                  selfVideo.setMuted(false)
                                }
                              }
                            }
                          )
                        }
                      }}
                    />

                    <a
                      className="AppBookingsTalk__bottom__buttons__a"
                      onClick={() => {
                        this.setState({
                          isActivePanel: true,
                        })
                      }}
                    />

                    <a
                      className={className(
                        "AppBookingsTalk__bottom__buttons__a",
                        {
                          "AppBookingsTalk__bottom__buttons__a--call-end": true,
                          "AppBookingsTalk__bottom__buttons__a--active": true,
                        }
                      )}
                      href={get(this.props, "data.booking_path")}
                    />
                  </div>
                </div>

                {get(this.state, "partnerVideo") &&
                  get(this.state, "overlayed") && (
                    <a
                      className="AppBookingsTalk__overlay"
                      onClick={() => {
                        const partnerVideo = get(
                          this.state,
                          "partnerVideo"
                        ) as any

                        partnerVideo.stop()

                        partnerVideo.play(get(this.state, "videoAreaPartner"), {
                          muted: false,
                        })

                        this.setState({
                          overlayed: false,
                        })
                      }}
                    >
                      Click to start
                    </a>
                  )}
              </React.Fragment>
            )}

            <div
              className={className("AppBookingsTalk__panel", {
                "AppBookingsTalk__panel--active": get(
                  this.state,
                  "isActivePanel"
                ),
              })}
            >
              <div className="AppBookingsTalk__panel__header">
                <a
                  className="AppBookingsTalk__panel__header__a"
                  onClick={() => {
                    this.setState({
                      isActivePanel: false,
                    })
                  }}
                />

                <p className="AppBookingsTalk__panel__header__p">
                  {get(this.props, "data.t.current.call_settings")}
                </p>
              </div>

              {(() => {
                let _data: any = [
                  {
                    h4: get(this.props, "data.t.current.audio_h4"),
                    items: [
                      {
                        dt: get(this.props, "data.t.current.audio"),
                        dd: get(
                          find(get(this.state, "audioinputs"), (x) => {
                            return (
                              get(x, "deviceId") ==
                              get(this.state, "audioinputCurrent")
                            )
                          }),
                          "label"
                        ),
                        onClick: () => {
                          this.setState({
                            isActivePanel: false,
                            isActivePanelAudio: true,
                          })
                        },
                      },
                    ],
                  },
                  {
                    h4: get(this.props, "data.t.current.output_h4"),
                    items: [
                      {
                        dt: get(this.props, "data.t.current.output_volume"),
                        range: (
                          <Volume
                            partnerAudio={get(this.state, "partnerAudio")}
                          />
                        ),
                      },
                    ],
                  },
                ]

                if (get(this.props, "data.package.video_usable")) {
                  _data = [
                    ..._data,
                    {
                      h4: get(this.props, "data.t.current.video_h4"),
                      items: [
                        {
                          dt: get(this.props, "data.t.current.video"),
                          dd: get(
                            find(get(this.state, "videoinputs"), (x) => {
                              return (
                                get(x, "deviceId") ==
                                get(this.state, "videoinputCurrent")
                              )
                            }),
                            "label"
                          ),
                          onClick: () => {
                            this.setState({
                              isActivePanel: false,
                              isActivePanelVideo: true,
                            })
                          },
                        },
                      ],
                    },
                  ]
                }

                /**
                // Disable flip function per https://trello.com/c/2B1dfR1u/1141-flip-horizontally-is-not-working-during-taking-on-web-call#comment-5fbca27d739f1c4b4ed62e69.
                if (false) {
                  _data = [
                    ..._data,
                    {
                      dt: get(this.props, "data.t.current.flip.horizontal"),
                      onClick: () => {},
                      checkbox: (
                        <div className="AppBookingsTalk__panel__dl__item__checkbox">
                          <input
                            type="checkbox"
                            className="AppForm__checkbox__input"
                            checked={Boolean(
                              get(this.state, "isFlippedX") || ""
                            )}
                            onChange={(e) => {
                              this.setState(
                                (prevState, props) => ({
                                  isFlippedX: !prevState.isFlippedX,
                                }),
                                () => {
                                  if (get(this.state, "isFlippedX")) {
                                    Cookies.set(VIDEO_FLIP_X_KEY, 1)
                                  } else {
                                    Cookies.remove(VIDEO_FLIP_X_KEY)
                                  }
                                }
                              )
                            }}
                          />
                        </div>
                      ),
                    },
                  ]
                }
                */

                return (
                  <dl className="AppBookingsTalk__panel__dl">
                    {_data.map((x: any, i: any) => (
                      <div className="AppBookingsTalk__panel__dl__item" key={i}>
                        <h4 className="AppBookingsTalk__panel__dl__item__h4">
                          {get(x, "h4")}
                        </h4>

                        {get(x, "items").map((y: any, j: number) => (
                          <a
                            key={j}
                            className="AppBookingsTalk__panel__dl__item__a"
                            onClick={() => {
                              if (y.onClick) {
                                y.onClick()
                              }
                            }}
                          >
                            <dt className="AppBookingsTalk__panel__dl__item__dt">
                              {get(y, "dt")}
                            </dt>

                            {get(y, "dd") && (
                              <dd className="AppBookingsTalk__panel__dl__item__dd">
                                <span className="AppBookingsTalk__panel__dl__item__dd__span">
                                  {get(y, "dd")}
                                </span>
                              </dd>
                            )}

                            {get(y, "checkbox") && get(y, "checkbox")}

                            {get(y, "range") && get(y, "range")}
                          </a>
                        ))}
                      </div>
                    ))}
                  </dl>
                )
              })()}
            </div>

            {[
              {
                p: get(this.props, "data.t.current.audio"),
                keyIsActivePanel: "isActivePanelAudio",
                keyInputs: "audioinputs",
                keyInputCurrent: "audioinputCurrent",
                keyCookie: AUDIO_DEVICE_ID_KEY,
              },
              {
                p: get(this.props, "data.t.current.video"),
                keyIsActivePanel: "isActivePanelVideo",
                keyInputs: "videoinputs",
                keyInputCurrent: "videoinputCurrent",
                keyCookie: VIDEO_DEVICE_ID_KEY,
              },
            ].map((x, i) => (
              <div
                key={i}
                className={className("AppBookingsTalk__panel", {
                  "AppBookingsTalk__panel--active": get(
                    this.state,
                    get(x, "keyIsActivePanel")
                  ),
                })}
              >
                <div className="AppBookingsTalk__panel__header">
                  <a
                    className="AppBookingsTalk__panel__header__a AppBookingsTalk__panel__header__a--back"
                    onClick={() => {
                      this.setState({
                        isActivePanel: true,
                        [get(x, "keyIsActivePanel")]: false,
                      })
                    }}
                  />

                  <p className="AppBookingsTalk__panel__header__p">
                    {get(x, "p")}
                  </p>
                </div>

                <div className="AppBookingsTalk__panel__list">
                  {(get(this.state, get(x, "keyInputs")) || []).map(
                    (y: any, j: number) => (
                      <a
                        className={className(
                          "AppBookingsTalk__panel__list__item",
                          {
                            "AppBookingsTalk__panel__list__item--active":
                              get(y, "deviceId") ==
                              get(this.state, get(x, "keyInputCurrent")),
                          }
                        )}
                        key={j}
                        onClick={() => {
                          const selfVideo = get(this.state, "selfVideo") as any

                          if (selfVideo) {
                            selfVideo.stop()
                            this.client.unpublish(selfVideo)
                          }

                          this.setState(
                            {
                              [get(x, "keyInputCurrent")]: get(y, "deviceId"),
                            },
                            () => {
                              Cookies.set(
                                get(x, "keyCookie"),
                                get(y, "deviceId")
                              )

                              window.setTimeout(() => {
                                this.playSelfStream()
                              }, 1000)
                            }
                          )
                        }}
                      >
                        <dt className="AppBookingsTalk__panel__list__item__dt">
                          {get(y, "label")}
                        </dt>
                      </a>
                    )
                  )}
                </div>
              </div>
            ))}

            {!get(this.props, "data.is_purchaser") && (
              <div className="AppBookingsTalk__memo">
                <a
                  className={className("AppBookingsTalk__memo__overlay", {
                    "AppBookingsTalk__memo__overlay--active": get(
                      this.state,
                      "isActivePanelMemo"
                    ),
                  })}
                  onClick={() => {
                    this.setState({
                      isActivePanelMemo: false,
                    })
                  }}
                />

                <div
                  className={className("AppBookingsTalk__memo__panel", {
                    "AppBookingsTalk__memo__panel--active": get(
                      this.state,
                      "isActivePanelMemo"
                    ),
                  })}
                >
                  <a
                    className="AppBookingsTalk__memo__panel__a"
                    onClick={() => {
                      this.setState({
                        isActivePanelMemo: false,
                      })
                    }}
                  />

                  <div className="AppBookingsTalk__memo__panel__wrapper">
                    <div className="AppUsersShow">
                      <div className="AppUsersShow__profile">
                        <img
                          className="AppUsersShow__profile__img"
                          src={get(
                            this.props,
                            "data.partner.icon_image_url_with_fallback"
                          )}
                        />

                        <p className="AppUsersShow__profile__name">
                          {get(this.props, "data.user.name")}
                        </p>

                        <p className="AppUsersShow__p">
                          {get(this.props, "data.user.description")}
                        </p>
                      </div>

                      {get(this.props, "data.user.last_screenshot") && (
                        <div className="AppUsersShow__memo">
                          <div className="AppUsersShow__memo__ss">
                            <p className="AppUsersShow__memo__label">
                              {get(this.props, "data.t.users_show.screenshot")}
                            </p>

                            <div className="AppBookingsShowAfterPublisher__label__image">
                              <img
                                src={get(
                                  this.props,
                                  "data.user.last_screenshot"
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {get(this.props, "data.user.past_bookings.length", 0) >
                        0 && (
                        <div className="AppUsersShow__past-talks">
                          <h3 className="AppUsersShow__past-talks__h3">
                            {get(
                              this.props,
                              "data.t.bookings_show_past_talk_title"
                            )}
                          </h3>

                          {(
                            get(this.props, "data.user.past_bookings") || []
                          ).map((x: any, i: number) => (
                            <div
                              key={i}
                              className="AppUsersShow__past-talks__card"
                            >
                              <div className="AppUsersShow__past-talks__card__top">
                                <img
                                  className="AppUsersShow__past-talks__card__top__img"
                                  src={get(
                                    this.props,
                                    "data.partner.icon_image_url_with_fallback"
                                  )}
                                />

                                <div className="AppUsersShow__past-talks__card__top__wrapper">
                                  <p className="AppUsersShow__past-talks__card__top__p">
                                    {get(this.props, "data.user.name")}
                                  </p>

                                  <h2 className="AppUsersShow__past-talks__card__top__h2">
                                    {get(x, "title")}
                                  </h2>
                                </div>
                              </div>

                              {!get(x, "lottery") && (
                                <div className="AppUsersShow__past-talks__card__bottom">
                                  <small className="AppUsersShow__past-talks__card__bottom__small">
                                    {get(x, "start_at_and_end_at.date")}
                                  </small>

                                  <p className="AppUsersShow__past-talks__card__bottom__p">
                                    {get(x, "start_at_and_end_at.time")}
                                  </p>
                                </div>
                              )}

                              {get(x, "memo") && (
                                <p className="AppUsersShow__past-talks__card__memo">
                                  {get(x, "memo")}
                                </p>
                              )}

                              <a
                                className="AppUsersShow__past-talks__card__a"
                                href={get(x, "after_booking_path")}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {get(this.props, "data.next_booking") &&
              get(this.state, "callEnded") && (
                <div
                  className={className("AppBookingsTalk__modal", {
                    "AppBookingsTalk__modal--active": get(
                      this.state,
                      "callEnded"
                    ),
                  })}
                >
                  <div className="AppBookingsTalk__modal__panel">
                    <div className="AppBookingsTalk__modal__panel__top">
                      <div className="AppBookingsTalk__modal__panel__top__wrapper">
                        <div className="AppBookingsTalk__modal__panel__top__top">
                          <img
                            className="AppBookingsTalk__modal__panel__top__img"
                            src={get(
                              this.props,
                              "data.partner.icon_image_url_with_fallback"
                            )}
                          />

                          <div className="AppBookingsTalk__modal__panel__top__content">
                            <p className="AppBookingsTalk__modal__panel__top__content__p">
                              {get(this.props, "data.partner.name")}
                            </p>

                            <h3 className="AppBookingsTalk__modal__panel__top__content__h3">
                              {get(
                                this.props,
                                "data.next_booking.package_title"
                              )}
                            </h3>
                          </div>
                        </div>

                        <time className="AppBookingsTalk__modal__panel__top__time">
                          {get(
                            this.props,
                            "data.next_booking.start_at_and_end_at.date"
                          )}
                        </time>

                        <p className="AppBookingsTalk__modal__panel__top__p">
                          {get(
                            this.props,
                            "data.next_booking.start_at_and_end_at.time"
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="AppBookingsTalk__modal__panel__bottom">
                      <p className="AppBookingsTalk__modal__panel__bottom__p">
                        {get(this.props, "data.t.current.next_booking.p")}
                      </p>

                      <small className="AppBookingsTalk__modal__panel__bottom__small">
                        {get(this.props, "data.t.current.next_booking.small")}
                      </small>

                      <a
                        className="Button"
                        href={get(this.props, "data.next_booking.path")}
                      >
                        {get(this.props, "data.t.current.next_booking.button")}
                      </a>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </FullHeight>
    )
  }
}

export default AppBookingsTalk

type FullHeightProps = {
  className: string
  children: React.ReactElement
}

const FullHeight = (props: FullHeightProps) => {
  const [__height, __heightSet] = React.useState(0)
  const resize = React.useRef<number>()

  React.useEffect(() => {
    const setHeight = () => {
      const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight

      __heightSet(windowHeight - 64) // var(--Nav-height)
    }

    setHeight()

    const onResize = () => {
      window.clearTimeout(resize.current)
      resize.current = window.setTimeout(setHeight, 1000)
    }

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <div
      className={props.className}
      style={{
        height: __height,
      }}
    >
      {props.children}
    </div>
  )
}

type VolumeProps = {
  partnerAudio: any | null
}

const Volume = ({partnerAudio}: VolumeProps) => {
  const [__volume, __volumeSet] = React.useState(100)
  const ___volumeTO = React.useRef<number>()

  React.useEffect(() => {
    if (partnerAudio) {
      window.clearTimeout(___volumeTO.current)

      ___volumeTO.current = window.setTimeout(() => {
        partnerAudio.setVolume(Number(__volume))
      }, 200)
    }
  }, [partnerAudio, __volume])

  if (!partnerAudio) {
    return null
  }

  return (
    <div className="AppBookingsTalk__panel__dl__item__range">
      <div
        className="AppBookingsTalk__panel__dl__item__range__bar"
        style={{
          width: `${__volume}%`,
        }}
      ></div>

      <input
        type="range"
        className="AppBookingsTalk__panel__dl__item__range__input"
        value={__volume}
        onChange={(e) => {
          __volumeSet(get(e, "target.value"))
        }}
      />
    </div>
  )
}
