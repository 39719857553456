import React from "react"
import isEmpty from "lodash/isEmpty"
import className from "classnames"
import moment from "moment-timezone"

import {tt} from "@lib/helpers"

const AdminLotteriesIndexFilter = ({date, scope, t}) => {
  const [__isActive, __isActiveSet] = React.useState(false)

  return (
    <div className="Filter">
      <div
        className={className("Filter__panel", {
          "Filter__panel--active": __isActive,
        })}
      >
        <div className="Filter__panel__status">
          <p className="Filter__form__label__span">{tt(t, "spots.status")}</p>

          {!moment(date).isSame(moment(), "day") && (
            <p className="Filter__panel__status__p">
              {tt(t, "spots.date")}：<strong>{date}</strong>
            </p>
          )}

          <p className="Filter__panel__status__p">
            {tt(t, "spots.status")}：
            <strong>
              {scope
                ? tt(t, `statuses.status_${scope}`)
                : tt(t, "spots.filter.all")}
            </strong>
          </p>
        </div>

        <a
          className="Filter__panel__handle"
          onClick={() => {
            __isActiveSet(!__isActive)
          }}
        >
          <span
            className={className("Filter__panel__handle__span", {
              "Filter__panel__handle__span--active": __isActive,
            })}
          >
            {tt(t, "spots.filter.handle")}
          </span>
        </a>

        <form
          className={className("Filter__form", {
            "Filter__form--active": __isActive,
          })}
        >
          <label className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "spots.date")}
            </span>

            <div className="Filter__form__label__input">
              <input type="date" name="date" defaultValue={date} />
            </div>
          </label>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "spots.status")}
            </span>

            <div className="Filter__form__label__input">
              <div className="Filter__form__label__input__checkboxes">
                <label className="CheckBox">
                  <input
                    type="radio"
                    name="scope"
                    defaultValue=""
                    defaultChecked={isEmpty(scope)}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "spots.filter.all")}
                  </div>
                </label>

                <label className="CheckBox">
                  <input
                    type="radio"
                    name="scope"
                    defaultValue="recruiting"
                    defaultChecked={scope == "recruiting"}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "statuses.status_recruiting")}
                  </div>
                </label>

                <label className="CheckBox">
                  <input
                    type="radio"
                    name="scope"
                    defaultValue="confirming"
                    defaultChecked={scope == "confirming"}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "statuses.status_confirming")}
                  </div>
                </label>

                <label className="CheckBox">
                  <input
                    type="radio"
                    name="scope"
                    defaultValue="confirmed"
                    defaultChecked={scope == "confirmed"}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "statuses.status_confirmed")}
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span Filter__form__label__span--no-smo">
              &nbsp;
            </span>

            <div className="Filter__form__label__input">
              <button className="Button Button--small">
                {tt(t, "spots.filter.submit")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

AdminLotteriesIndexFilter.propTypes = {}

export default AdminLotteriesIndexFilter
