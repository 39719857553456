import React from "react"
import PropTypes from "prop-types"
import {get, find} from "lodash"
import className from "classnames"
import moment from "moment-timezone"

import {tt, axios} from "@lib/helpers"

import Countdown from "@components/molecules/Countdown"

class AppLanePackagesTalks extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  $$startAt = moment(get(this.props, "data.package.bulk_start_at"))
  $$endAt = moment(get(this.props, "data.package.bulk_end_at"))

  state = {
    __activeSpot: null,
  }

  // The poller is for updating the queue.
  poller = async () => {
    if (moment().isBefore(this.$$endAt)) {
      const res = await axios.get(
        get(this.props, "data.active_spot_lane_packages_package_path")
      )

      this.setState(
        {
          __activeSpot: get(res, "data"),
        },
        () => {
          window.setTimeout(() => {
            this.poller()
          }, 5000)
        }
      )
    }
  }

  // The timer is about package state and navigating to a call
  // if any buyer in the queue is present.
  timer = async () => {
    if (moment().isBefore(this.$$startAt)) {
      window.setTimeout(async () => {
        this.timer()
      }, 1000)
    } else if (moment().isBetween(this.$$startAt, this.$$endAt)) {
      if (get(this.state, "__activeSpot.active_spot")) {
        this.goToFirstTalk()
      } else {
        window.setTimeout(() => {
          this.timer()
        }, 1000)
      }
    } else {
      this.setState({
        __callEnded: true,
      })
    }
  }

  goToFirstTalk = () => {
    window.location.href = `/lane_packages/${get(
      this.props,
      "data.package.id"
    )}/spots/${get(this.state, "__activeSpot.active_spot.token")}/talk`
  }

  componentDidMount = () => {
    this.poller()
    this.timer()
  }

  render = () => {
    const {data} = this.props
    const {t} = data

    return (
      <div className="AppBookingsTalk AppBookingsTalk--100vh">
        <div className="kontainer-app">
          <div className="AppBookingsTalk__wrapper">
            <div className="AppBookingsTalk__lane">
              <div className="AppBookingsTalk__lane__wrapper">
                {get(this.state, "__callEnded") ? (
                  <React.Fragment>
                    <h3 className="AppBookingsTalk__lane__h3">
                      {tt(t, "bookings_talk_lane.waiting_room.thanks_msg.h3")}
                    </h3>

                    <p className="AppBookingsTalk__lane__p">
                      {tt(t, "bookings_talk_lane.waiting_room.thanks_msg.p")}
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h3
                      className="AppBookingsTalk__lane__h3"
                      dangerouslySetInnerHTML={{
                        __html: tt(t, "bookings_talk_lane.waiting_room.h3", {
                          time: this.$$startAt.format("HH:mm"),
                        }),
                      }}
                    />

                    <Countdown
                      $$startAt={this.$$startAt}
                      $$endAt={this.$$endAt}
                      t={get(t, "bookings_talk_lane.countdown")}
                    />

                    {false && (
                      <React.Fragment>
                        <p className="AppBookingsTalk__lane__p">
                          <strong>15</strong> 秒後に次のトークに遷移します
                        </p>
                      </React.Fragment>
                    )}

                    {get(this.state, "__activeSpot.every_spot.length", 0) >
                      0 && (
                      <div className="AppBookingsTalk__lane__nexts">
                        {(get(this.state, "__activeSpot.every_spot") || []).map(
                          (x, i) => {
                            const _spot = find(
                              get(this.props, "data.spots"),
                              (y) => get(y, "token") == x
                            )

                            return (
                              <div
                                className="AppBookingsTalk__lane__next"
                                key={i}
                              >
                                <p className="AppBookingsTalk__lane__next__p">
                                  Next
                                </p>

                                <img
                                  className="AppBookingsTalk__lane__next__img"
                                  src={get(
                                    _spot,
                                    "user.icon_image_url_with_fallback"
                                  )}
                                />

                                <span className="AppBookingsTalk__lane__next__span">
                                  {get(_spot, "user.name")} ({x})
                                </span>
                              </div>
                            )
                          }
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AppLanePackagesTalks
