import React from "react"
import get from "lodash/get"
import take from "lodash/take"
import sumBy from "lodash/sumBy"
import entries from "lodash/entries"
import omitBy from "lodash/omitBy"
import last from "lodash/last"
import className from "classnames"
import moment from "moment-timezone"
import qs from "qs"

import {axios} from "@lib/helpers"

import Graph from "@components/molecules/Graph"
import DatePicker from "@components/molecules/DatePicker"
import {tt} from "@lib/helpers"

type AdminDashboardIndexProps = {
  data: {
    start_date: string
    end_date: string

    spots_stats_path: string
    revenues_stats_path: string
    users_stats_path: string
    followers_stats_path: string
    publishers_stats_path: string
    active_publishers_stats_path: string

    agencies_stats_path?: string
    chip_sales_stats_path?: string
    coupon_deductions_stats_path?: string
    active_agencies_stats_path?: string
    new_users_stats_path?: string
    new_publishers_stats_path?: string
    new_agencies_stats_path?: string
    admin_stats_agencies_path?: string
    admin_stats_publishers_path?: string
    admin_stats_spots_path?: string
    admin_stats_users_path?: string

    is_agency_dashboard?: boolean
    t: RailsI18n.T
    locale?: string
  }
}

type StatsData = {
  date: string
  value: number
}

type StatsDataSet = {
  data: StatsData[]
  dataCompare: StatsData[] | undefined
  dataCumulative: StatsData[] | undefined
  dataCumulativeCompare: StatsData[] | undefined
  dataKey: string
  isLoaded: boolean
}

type BoxData = {
  h2: string
  description: string
  dataSet: StatsDataSet
}

type AdminDashboardIndexState = {
  __data: {
    spots_stats?: StatsDataSet
    revenues_stats?: StatsDataSet
    users_stats?: StatsDataSet
    followers_stats?: StatsDataSet
    publishers_stats?: StatsDataSet
    active_publishers_stats?: StatsDataSet

    agencies_stats_path?: StatsDataSet
    chip_sales_stats_path?: StatsDataSet
    coupon_deductions_stats_path?: StatsDataSet
    active_agencies_stats_path?: StatsDataSet
    new_users_stats_path?: StatsDataSet
    new_publishers_stats_path?: StatsDataSet
    new_agencies_stats_path?: StatsDataSet
    admin_stats_agencies_path?: StatsDataSet
    admin_stats_publishers_path?: StatsDataSet
    admin_stats_spots_path?: StatsDataSet
    admin_stats_users_path?: StatsDataSet
  }
}

class AdminDashboardIndex extends React.Component<
  AdminDashboardIndexProps,
  AdminDashboardIndexState
> {
  $$startDate = moment(this.props.data.start_date)
  $$endDate = moment(this.props.data.end_date)

  state: AdminDashboardIndexState = {
    __data: {},
  }

  getData = ({dataSet, dataIndex}: any) => {
    const _data = dataIndex == 1 ? dataSet?.dataCumulative : dataSet?.data

    return Array.from(Array(_data?.length || 0)).map((_, i) => {
      return {
        key: _data?.[i]?.date,
        values: [
          _data?.[i]?.value,
          ...(this.getIsComparing()
            ? [
                (dataIndex == 1
                  ? dataSet?.dataCumulativeCompare?.[i]?.value
                  : dataSet?.dataCompare?.[i]?.value) || 0,
              ]
            : []),
        ],
      }
    })
  }

  getIsComparing = () => {
    return qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })?.["compare"]
  }

  getDiffDays = () => {
    const _diffDays = Math.ceil(
      moment.duration(this.$$endDate.diff(this.$$startDate)).as("days")
    )

    return {
      compare_start_date: moment(this.$$startDate)
        .subtract(_diffDays, "days")
        .format("YYYY-MM-DD"),
      compare_end_date: moment(this.$$startDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
    }
  }

  componentDidMount = () => {
    const {data} = this.props

    ;[
      {
        href: data?.spots_stats_path,
        hrefCumulative: data?.admin_stats_spots_path,
        dataKey: "spots_stats",
      },
      {
        href: data?.revenues_stats_path,
        dataKey: "revenues_stats",
      },
      {
        href: data?.users_stats_path,
        hrefCumulative: data?.admin_stats_users_path,
        dataKey: "users_stats",
        query: {
          query: "booking",
        },
      },
      {
        href: data?.followers_stats_path,
        dataKey: "followers_stats",
      },
      {
        href: data?.publishers_stats_path,
        dataKey: "publishers_stats",
      },
      {
        href: data?.agencies_stats_path,
        dataKey: "agencies_stats",
      },
      {
        href: data?.chip_sales_stats_path,
        dataKey: "chip_sales_stats",
      },
      {
        href: data?.coupon_deductions_stats_path,
        dataKey: "coupon_deductions_stats",
      },
      {
        href: data?.active_agencies_stats_path,
        dataKey: "active_agencies_stats",
      },
      {
        href: data?.active_publishers_stats_path,
        dataKey: "active_publishers_stats",
      },
      {
        href: data?.new_users_stats_path,
        hrefCumulative: data?.admin_stats_users_path,
        dataKey: "new_users_stats",
      },
      {
        href: data?.new_agencies_stats_path,
        hrefCumulative: data?.admin_stats_agencies_path,
        dataKey: "new_agencies_stats",
      },
      {
        href: data?.new_publishers_stats_path,
        hrefCumulative: data?.admin_stats_publishers_path,
        dataKey: "new_publishers_stats",
      },
    ].forEach(async (x) => {
      if (x?.href) {
        const res = await axios.get(x?.href, {
          start_date: this.$$startDate.format("YYYY-MM-DD"),
          end_date: this.$$endDate.format("YYYY-MM-DD"),
        })

        let resCumulative: any

        if (x?.hrefCumulative) {
          resCumulative = await axios.get(x?.hrefCumulative, {
            start_date: this.$$startDate.format("YYYY-MM-DD"),
            end_date: this.$$endDate.format("YYYY-MM-DD"),
            ...x?.query,
          })
        }

        let resCompare: any
        let resCumulativeCompare: any

        if (this.getIsComparing()) {
          resCompare = await axios.get(x?.href, {
            start_date: this.getDiffDays()?.compare_start_date,
            end_date: this.getDiffDays()?.compare_end_date,
          })

          if (x?.hrefCumulative) {
            resCumulativeCompare = await axios.get(x?.hrefCumulative, {
              start_date: this.getDiffDays()?.compare_start_date,
              end_date: this.getDiffDays()?.compare_end_date,
              ...x?.query,
            })
          }
        }

        this.setState((prevState) => {
          const _statsDataSet: StatsDataSet = {
            data: res?.data?.data,
            dataCompare: resCompare?.data?.data,
            dataCumulative: resCumulative?.data?.data,
            dataCumulativeCompare: resCumulativeCompare?.data?.data,
            dataKey: x?.dataKey,
            isLoaded: true,
          }

          return {
            ...prevState,
            __data: {
              ...prevState.__data,
              [x?.dataKey]: _statsDataSet,
            },
          }
        })
      }
    })
  }

  render = () => {
    const {data} = this.props

    return (
      <div className="AdminDashboardIndex">
        <div className="Back Back--stal">
          <div className="Back__a Back__a--header-only">
            <span className="Back__a__span Back__a__span--stal">
              {tt(this.props, "data.t.current.title")}
            </span>
          </div>
        </div>

        <div className="AdminDashboardIndex__header">
          <h1 className="Main__header__h1">
            {tt(this.props, "data.t.current.title")}
          </h1>

          <DatePicker
            start_date={this.$$startDate.format("YYYY-MM-DD")}
            end_date={this.$$endDate.format("YYYY-MM-DD")}
            locale={this.props.data.locale}
          />

          {this.getIsComparing() ? (
            <React.Fragment>
              <DatePicker
                start_date={get(this.getDiffDays(), "compare_start_date")}
                end_date={get(this.getDiffDays(), "compare_end_date")}
                locale={this.props.data.locale}
                compared
              />
            </React.Fragment>
          ) : (
            <a
              className="AdminDashboardIndex__header__a"
              href={`?${qs.stringify(
                omitBy(
                  {
                    ...qs.parse(window.location.search, {
                      ignoreQueryPrefix: true,
                    }),
                    start_date: this.$$startDate.format("YYYY-MM-DD"),
                    end_date: this.$$endDate.format("YYYY-MM-DD"),
                    compare: 1,
                  },
                  (x) => x == null || x == undefined
                )
              )}`}
            >
              {tt(this.props, "data.t.current.add_period")}
            </a>
          )}
        </div>

        <div className="AdminDashboardIndex__boxs">
          {get(data, "is_agency_dashboard") ? (
            <React.Fragment>
              {[
                [
                  {
                    h2: tt(this.props, "data.t.current.sales.title"),
                    description: tt(
                      this.props,
                      "data.t.current.sales.description"
                    ),
                    dataSet: get(this.state, "__data.revenues_stats"),
                    prefix: "¥",
                  },
                  {
                    h2: tt(this.props, "data.t.current.number_of_sales.title"),
                    description: tt(
                      this.props,
                      "data.t.current.number_of_sales.description"
                    ),
                    dataSet: get(this.state, "__data.spots_stats"),
                  },
                ],
                [
                  {
                    h2: tt(
                      this.props,
                      "data.t.current.talk_implemented_users.title"
                    ),
                    description: tt(
                      this.props,
                      "data.t.current.talk_implemented_users.description"
                    ),
                    dataSet: get(this.state, "__data.users_stats"),
                  },
                  {
                    h2: tt(
                      this.props,
                      "data.t.current.talk_conducted_publishers.title"
                    ),
                    description: tt(
                      this.props,
                      "data.t.current.talk_conducted_publishers.description"
                    ),
                    dataSet: get(this.state, "__data.active_publishers_stats"),
                  },
                ],
              ].map((x, i) => (
                <Boxes
                  boxesData={x}
                  getData={this.getData}
                  key={i}
                  t={get(this.props, "data.t.current")}
                />
              ))}

              <div className="AdminDashboardIndex__boxs__bottom">
                <Table
                  tableData={{
                    h2: tt(this.props, "data.t.current.by_publishers.title"),
                    dataSet: get(this.state, "__data.publishers_stats"),
                  }}
                  headers={[
                    {
                      label: tt(
                        this.props,
                        "data.t.current.by_publishers.headers.publisher"
                      ),
                    },
                    {
                      label: tt(
                        this.props,
                        "data.t.current.by_publishers.headers.agency"
                      ),
                    },
                    {
                      label: tt(
                        this.props,
                        "data.t.current.by_publishers.headers.sales"
                      ),
                    },
                    {
                      label: tt(
                        this.props,
                        "data.t.current.by_publishers.headers.spot"
                      ),
                    },
                    {
                      label: tt(
                        this.props,
                        "data.t.current.by_publishers.headers.fan"
                      ),
                    },
                  ]}
                />

                <Box
                  boxData={{
                    h2: tt(this.props, "data.t.current.fans.title"),
                    description: tt(
                      this.props,
                      "data.t.current.fans.description"
                    ),
                    dataSet: get(this.state, "__data.followers_stats"),
                  }}
                  getData={this.getData}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {[
                [
                  {
                    h2: tt(this.props, "data.t.current.sales.title"),
                    description: tt(
                      this.props,
                      "data.t.current.sales.description"
                    ),
                    dataSet: get(this.state, "__data.revenues_stats"),
                    prefix: "¥",
                  },
                  {
                    h2: tt(this.props, "data.t.current.number_of_sales.title"),
                    description: tt(
                      this.props,
                      "data.t.current.number_of_sales.description"
                    ),
                    dataSet: get(this.state, "__data.spots_stats"),
                  },
                ],
                [
                  {
                    h2: tt(
                      this.props,
                      "data.t.current.talk_implemented_users.title"
                    ),
                    description: tt(
                      this.props,
                      "data.t.current.talk_implemented_users.description"
                    ),
                    dataSet: get(this.state, "__data.users_stats"),
                  },
                  {
                    h2: tt(
                      this.props,
                      "data.t.current.talk_conducted_agencies.title"
                    ),
                    description: tt(
                      this.props,
                      "data.t.current.talk_conducted_agencies.description"
                    ),
                    dataSet: get(this.state, "__data.active_agencies_stats"),
                  },
                  {
                    h2: tt(
                      this.props,
                      "data.t.current.talk_conducted_publishers.title"
                    ),
                    description: tt(
                      this.props,
                      "data.t.current.talk_conducted_publishers.description"
                    ),
                    dataSet: get(this.state, "__data.active_publishers_stats"),
                  },
                  {
                    h2: tt(this.props, "data.t.current.new_users.title"),
                    description: tt(
                      this.props,
                      "data.t.current.new_users.description"
                    ),
                    dataSet: get(this.state, "__data.new_users_stats"),
                  },
                  {
                    h2: tt(this.props, "data.t.current.new_agencies.title"),
                    description: tt(
                      this.props,
                      "data.t.current.new_agencies.description"
                    ),
                    dataSet: get(this.state, "__data.new_agencies_stats"),
                  },
                  {
                    h2: tt(this.props, "data.t.current.new_publishers.title"),
                    description: tt(
                      this.props,
                      "data.t.current.new_publishers.description"
                    ),
                    dataSet: get(this.state, "__data.new_publishers_stats"),
                  },
                  {
                    h2: tt(this.props, "data.t.current.fans.title"),
                    description: tt(
                      this.props,
                      "data.t.current.fans.description"
                    ),
                    dataSet: get(this.state, "__data.followers_stats"),
                  },
                  {
                    h2: tt(this.props, "data.t.current.chip_sales.title"),
                    description: tt(
                      this.props,
                      "data.t.current.chip_sales.description"
                    ),
                    dataSet: get(this.state, "__data.chip_sales_stats"),
                  },
                  {
                    h2: tt(this.props, "data.t.current.coupon_use.title"),
                    description: tt(
                      this.props,
                      "data.t.current.coupon_use.description"
                    ),
                    dataSet: get(this.state, "__data.coupon_deductions_stats"),
                  },
                ],
              ].map((x, i) => (
                <Boxes
                  boxesData={x}
                  getData={this.getData}
                  key={i}
                  t={get(this.props, "data.t.current")}
                />
              ))}

              <div className="AdminDashboardIndex__boxs__bottom">
                {[
                  {
                    h2: tt(this.props, "data.t.current.by_publishers.title"),
                    dataSet: get(this.state, "__data.publishers_stats"),
                    headers: [
                      {
                        label: tt(
                          this.props,
                          "data.t.current.by_publishers.headers.publisher"
                        ),
                      },
                      {
                        label: tt(
                          this.props,
                          "data.t.current.by_publishers.headers.agency"
                        ),
                      },
                      {
                        label: tt(
                          this.props,
                          "data.t.current.by_publishers.headers.sales"
                        ),
                      },
                      {
                        label: tt(
                          this.props,
                          "data.t.current.by_publishers.headers.spot"
                        ),
                      },
                      {
                        label: tt(
                          this.props,
                          "data.t.current.by_publishers.headers.fan"
                        ),
                      },
                    ],
                  },
                  {
                    h2: tt(this.props, "data.t.current.by_agencies.title"),
                    dataSet: get(this.state, "__data.agencies_stats"),
                    tableKey: "agencies",
                    headers: [
                      {
                        label: tt(
                          this.props,
                          "data.t.current.by_agencies.headers.agency"
                        ),
                      },
                      {
                        label: tt(
                          this.props,
                          "data.t.current.by_agencies.headers.sales"
                        ),
                      },
                      {
                        label: tt(
                          this.props,
                          "data.t.current.by_agencies.headers.spot"
                        ),
                      },
                      {
                        label: tt(
                          this.props,
                          "data.t.current.by_agencies.headers.fan"
                        ),
                      },
                    ],
                  },
                ].map((x, i) => (
                  <Table
                    tableData={x}
                    key={i}
                    headers={x?.headers}
                    tableKey={x?.tableKey}
                  />
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default AdminDashboardIndex

const Boxes = ({boxesData, getData, t}: any) => {
  return (
    <div
      className={className("AdminDashboardIndex__boxs__top", {
        "AdminDashboardIndex__boxs__top--dual":
          get(boxesData, "length", 0) == 2,
      })}
    >
      {boxesData.map((y: any, j: number) => {
        return (
          <Box
            key={j}
            boxesDataLength={boxesData?.length}
            boxData={y}
            getData={getData}
            t={t}
          />
        )
      })}
    </div>
  )
}

const Box = ({
  boxesDataLength,
  getData,
  boxData,
  t,
}: {
  boxesDataLength?: number
  getData: any
  boxData: BoxData
  t: RailsI18n.T
}) => {
  const [__dataIndex, __dataIndexSet] = React.useState(0)

  return (
    <div
      className="AdminDashboardIndex__box"
      data-key={boxData?.dataSet?.dataKey}
    >
      <div className="AdminDashboardIndex__box__header">
        <h2 className="AdminDashboardIndex__box__h2">{get(boxData, "h2")}</h2>

        <p className="AdminDashboardIndex__box__p">
          {boxData?.dataSet?.isLoaded ? (
            <React.Fragment>
              {get(boxData, "prefix")}
              {(__dataIndex == 0
                ? sumBy(boxData?.dataSet?.data?.map?.((x) => x?.value))
                : last(boxData?.dataSet?.dataCumulative)?.value
              )?.toLocaleString()}
            </React.Fragment>
          ) : (
            <React.Fragment>&nbsp;</React.Fragment>
          )}
        </p>

        <p className="AdminDashboardIndex__box__description">
          {get(boxData, "description")}
        </p>
      </div>

      <div
        className={className("AdminDashboardIndex__box__switch", {
          "AdminDashboardIndex__box__switch--active":
            boxData?.dataSet?.dataCumulative,
        })}
      >
        <div className="AdminDashboardIndex__box__switch__wrapper">
          {[
            {
              label: tt(t, "toggle.total"),
            },
            {
              label: tt(t, "toggle.grand_total"),
            },
          ].map((x, i) => (
            <a
              className={className("AdminDashboardIndex__box__switch__a", {
                "AdminDashboardIndex__box__switch__a--active": i == __dataIndex,
              })}
              key={i}
              onClick={() => {
                __dataIndexSet(i)
              }}
            >
              {x.label}
            </a>
          ))}
        </div>
      </div>

      <Graph
        initialHeight={boxesDataLength == 2 ? 300 : 240}
        data={getData({dataSet: boxData?.dataSet, dataIndex: __dataIndex})}
        isLoaded={boxData?.dataSet?.isLoaded}
        followClientHeight={false}
      />
    </div>
  )
}

const Table = ({tableData, tableKey, headers = []}: any) => {
  const dataLimit = 5
  const [__isActiveSeeMore, __isActiveSeeMoreSet] = React.useState(false)

  return (
    <div className="AdminDashboardIndex__box AdminDashboardIndex__box--table">
      <h2 className="AdminDashboardIndex__box__h2">{get(tableData, "h2")}</h2>

      {tableData?.dataSet?.isLoaded ? (
        <React.Fragment>
          <div className="AdminDashboardIndex__table">
            <div className="AdminDashboardIndex__table__row">
              {headers.map((x: any, i: number) => (
                <div
                  className="AdminDashboardIndex__table__col AdminDashboardIndex__table__col--header"
                  key={i}
                >
                  <a className="AdminDashboardIndex__table__col__header">
                    {get(x, "label")}
                  </a>
                </div>
              ))}
            </div>

            {(() => {
              let rows = entries(get(tableData, "dataSet.data"))

              if (!__isActiveSeeMore) {
                rows = take(rows, dataLimit)
              }

              return (
                <React.Fragment>
                  {rows.map((x, i) => (
                    <div className="AdminDashboardIndex__table__row" key={i}>
                      <div className="AdminDashboardIndex__table__col">
                        <a
                          href={
                            tableKey == "agencies"
                              ? `/${get(
                                  window,
                                  "gon.current_locale"
                                )}/admin/agencies/${get(x, "1.id")}/details`
                              : `/${get(
                                  window,
                                  "gon.current_locale"
                                )}/admin/publishers/${get(x, "1.token")}`
                          }
                          className="AdminDashboardIndex__table__col__user__p"
                        >
                          {get(x, "1.custom_name")
                            ? get(x, "1.custom_name")
                            : get(x, "0")}
                        </a>
                      </div>

                      {get(x, "1.agency_name") && (
                        <div className="AdminDashboardIndex__table__col">
                          <a
                            href={`/${get(
                              window,
                              "gon.current_locale"
                            )}/admin/agencies/${get(x, "1.agency_id")}/details`}
                          >
                            {get(x, "1.agency_name")}
                          </a>
                        </div>
                      )}

                      <div className="AdminDashboardIndex__table__col">
                        <span className="AdminDashboardIndex__table__col__span">
                          {get(
                            headers,
                            `${get(headers, "length", 0) - 3}.label`
                          )}
                          :{" "}
                        </span>

                        {Number(get(x, "1.revenue")).toLocaleString()}
                      </div>

                      <div className="AdminDashboardIndex__table__col">
                        <span className="AdminDashboardIndex__table__col__span">
                          {get(
                            headers,
                            `${get(headers, "length", 0) - 2}.label`
                          )}
                          :{" "}
                        </span>

                        {Number(get(x, "1.spots_count")).toLocaleString()}
                      </div>

                      <div className="AdminDashboardIndex__table__col">
                        <span className="AdminDashboardIndex__table__col__span">
                          {get(
                            headers,
                            `${get(headers, "length", 0) - 1}.label`
                          )}
                          :{" "}
                        </span>

                        {Number(get(x, "1.followers_count")).toLocaleString()}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              )
            })()}
          </div>

          {entries(get(tableData, "dataSet.data")).length > dataLimit &&
            !__isActiveSeeMore && (
              <a
                className="AdminDashboardIndex__more"
                onClick={() => {
                  __isActiveSeeMoreSet(true)
                }}
              >
                もっと見る
              </a>
            )}
        </React.Fragment>
      ) : (
        <p className="AdminDashboardIndex__loading">Loading …</p>
      )}
    </div>
  )
}
