import React from "react"
import get from "lodash/get"
import omit from "lodash/omit"
// import className from "classnames"
import * as queryString from "query-string"

import {useSearchSuggestion} from "@lib/hooks"

const SearchSuggestion = ({
  query,
  query_key = "name",
  query_key_additional,
  suggestion_url,
  placeholder,
  t,
}) => {
  const {__text, __textSet, __isSearchingSet, $results, getQueryWithoutPage} =
    useSearchSuggestion({query, query_key, suggestion_url})

  return (
    <div className="SearchSuggestion">
      <form
        className="SearchSuggestion__form"
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()

          window.location.href = `?${queryString.stringify({
            ...getQueryWithoutPage(),
            [query_key]: __text,
          })}`
        }}
      >
        <div className="SearchSuggestion__form__label">
          <span className="SearchSuggestion__form__label__span">
            {get(t, "form_name")}
          </span>

          <div className="SearchSuggestion__form__label__input">
            <input
              type="text"
              name={query_key}
              value={__text}
              placeholder={placeholder}
              onChange={(e) => {
                __textSet(get(e, "target.value"))

                if (suggestion_url) {
                  __isSearchingSet(true)
                }
              }}
            />

            {$results}
          </div>
        </div>

        <div className="SearchSuggestion__form__label">
          <span className="SearchSuggestion__form__label__span SearchSuggestion__form__label__span--no-stal">
            &nbsp;
          </span>

          <div className="SearchSuggestion__form__label__input">
            <div className="Buttons">
              <button className="Button Button--small">
                {get(t, "search")}
              </button>

              <a
                className="Button Button--small Button--white"
                href={`?${queryString.stringify({
                  ...omit(
                    getQueryWithoutPage(),
                    query_key_additional
                      ? [query_key, ...query_key_additional]
                      : query_key
                  ),
                })}`}
              >
                {get(t, "clear")}
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

SearchSuggestion.propTypes = {}

export default SearchSuggestion
