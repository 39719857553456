import React from "react"
import filter from "lodash/filter"
import isNil from "lodash/isNil"
import find from "lodash/find"
import get from "lodash/get"
import className from "classnames"
import "agora-rtc-sdk-ng"
import Cookies from "js-cookie"
import moment from "moment-timezone"

import {
  tt,
  axios,
  VIDEO_DEVICE_ID_KEY,
  AUDIO_DEVICE_ID_KEY,
  AUDIO_MUTE_KEY,
  VIDEO_MUTE_KEY,
} from "@lib/helpers"

import Countdown from "@components/molecules/Countdown"
import DialCountdown from "@components/molecules/DialCountdown"

type AppLanePackagesSpotsTalkProps = {
  data: {
    appId: any
    channelKey: any
    token: any
    uid: any
    is_purchaser: boolean
    me: {
      icon_image_url_with_fallback: string
    }
    partner: {
      icon_image_url_with_fallback: string
    }
    spot: {
      id: number
      package_id: string
    }
    spots: {
      token: string
      user: {
        name: string
        icon_image_url_with_fallback: string
      }
    }[]
    package: {
      video_usable: boolean
      partner_video_usable: boolean
      bulk_start_at: string
      bulk_end_at: string
    }
    lane_packages_spot_queue_path: string
    lane_packages_spot_refresh_path: string
    lane_packages_spot_exit_path: string
    active_spot_lane_packages_package_path: string
    QUEUEABLE_TIME: number
    bookings_path: string
    t: any
  }
}

type AppLanePackagesSpotsTalkState = {
  __height: number
  __calling: boolean
  __callEnded: boolean
  __overlayed: boolean
  selfVideo: any | null
  selfAudio: any | null
  partnerVideo: any | null
  partnerAudio: any | null
  __audioinputs: any
  __audioinputCurrent: any
  __videoinputs: any
  __videoinputCurrent: any
  __videoAreaSelf: "videoAreaFront" | "videoAreaBack"
  __videoAreaPartner: "videoAreaFront" | "videoAreaBack"
  __queue: {
    exitable_time?: number
    call_start?: number
    duration?: number
    queue?: any
  }
  __activeSpot: {
    end_at?: any
    active_spot?: {
      token?: any
    }
  }
  __disableExitButton: any
  __$$callStartAt: any
  __$$callEndAt: any
  __isActivePanel: boolean
  __isActivePanelAudio: boolean
  __isActivePanelVideo: boolean
  __isMuteAudio: boolean
  __isMuteVideoSelf: boolean
  __isMuteVideoPartner: boolean
}

class AppLanePackagesSpotsTalk extends React.Component<
  AppLanePackagesSpotsTalkProps,
  AppLanePackagesSpotsTalkState
> {
  client = AgoraRTC.createClient({mode: "live", codec: "h264"})

  $$startAt = moment(this.props.data?.package?.bulk_start_at)
  $$endAt = moment(this.props.data?.package?.bulk_end_at)
  $$queueAble = moment(this.$$startAt).subtract(
    this.props.data?.QUEUEABLE_TIME,
    "seconds"
  )

  resizeTO: number | null = null

  state: AppLanePackagesSpotsTalkState = {
    __height: 0,
    __calling: false,
    __callEnded: false,
    __overlayed: true,
    selfVideo: null,
    selfAudio: null,
    partnerVideo: null,
    partnerAudio: null,
    __audioinputs: null,
    __audioinputCurrent: Cookies.get(AUDIO_DEVICE_ID_KEY)
      ? Cookies.get(AUDIO_DEVICE_ID_KEY)
      : null,
    __videoinputs: null,
    __videoinputCurrent: Cookies.get(VIDEO_DEVICE_ID_KEY)
      ? Cookies.get(VIDEO_DEVICE_ID_KEY)
      : null,
    __videoAreaSelf: "videoAreaFront",
    __videoAreaPartner: "videoAreaBack",
    __queue: {},
    __activeSpot: {},
    __disableExitButton: false,
    __$$callStartAt: null,
    __$$callEndAt: null,
    __isActivePanel: false,
    __isActivePanelAudio: false,
    __isActivePanelVideo: false,
    __isMuteAudio: Cookies.get(AUDIO_MUTE_KEY)
      ? Boolean(Cookies.get(AUDIO_MUTE_KEY))
      : false,
    __isMuteVideoSelf: Cookies.get(VIDEO_MUTE_KEY)
      ? Boolean(Cookies.get(VIDEO_MUTE_KEY))
      : false,
    __isMuteVideoPartner: false,
  }

  setHeight = () => {
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    this.setState({
      __height: windowHeight - 64, // var(--Nav-height)
    })
  }

  onResize = () => {
    window.clearTimeout(this.resizeTO ? this.resizeTO : undefined)
    this.resizeTO = window.setTimeout(() => {
      this.setHeight()
    }, 1000)
  }

  pollerBuyer = async () => {
    if (moment().isBefore(this.$$queueAble)) {
      window.setTimeout(() => {
        this.pollerBuyer()
      }, 1000)
    } else {
      const res = await axios.put(
        this.props.data?.lane_packages_spot_queue_path
      )

      this.setState(
        {
          __queue: res?.data,
        },
        () => {
          this.pollerBuyerRefresh()
          this.pollerBuyerExitQueueButton()
        }
      )
    }
  }

  pollerBuyerRefresh = async () => {
    if (!this.state.__calling) {
      const res = await axios.get(
        this.props.data?.lane_packages_spot_refresh_path
      )

      this.setState(
        {
          __queue: res?.data,
        },
        () => {
          if (res?.data?.queue == null) {
            this.setState({
              __calling: false,
              __callEnded: true,
            })
          } else {
            window.setTimeout(() => {
              this.pollerBuyerRefresh()
            }, 30000)
          }
        }
      )
    }
  }

  pollerBuyerExitQueueButton = () => {
    if (
      moment().isSameOrAfter(
        moment(
          this.state.__queue?.exitable_time
            ? this.state.__queue?.exitable_time * 1000
            : undefined
        )
      )
    ) {
      this.setState({
        __disableExitButton: true,
      })
    } else {
      window.setTimeout(() => {
        this.pollerBuyerExitQueueButton()
      }, 1000)
    }
  }

  redirectToWaitingRoom = () => {
    if (this.state.selfVideo) {
      this.client.leave()
    }

    window.location.href = `/lane_packages/${this.props.data?.spot?.package_id}/talks`
  }

  pollerPublisher = async () => {
    if (this.state.__activeSpot?.end_at && moment().isBefore(this.$$endAt)) {
      if (
        moment().isSameOrBefore(
          moment(
            this.state.__activeSpot?.end_at
              ? this.state.__activeSpot?.end_at * 1000
              : undefined
          )
        )
      ) {
        window.setTimeout(() => {
          this.pollerPublisher()
        }, 1000)
      } else {
        const res = await axios.get(
          this.props.data?.active_spot_lane_packages_package_path
        )

        if (!res?.data?.active_spot) {
          // Queue is empty.
          this.redirectToWaitingRoom()
        } else if (
          res?.data?.active_spot?.token ==
          this.state.__activeSpot?.active_spot?.token
        ) {
          // First place in the queue is currently active.
          window.setTimeout(() => {
            this.pollerPublisher()
          }, 1000)
        } else {
          // First place in the queue is the next call.
          window.location.href = `/lane_packages/${this.props.data?.spot?.package_id}/spots/${res?.data?.active_spot.token}/talk`
        }
      }
    } else {
      this.redirectToWaitingRoom()
    }
  }

  playSelfStream = async () => {
    try {
      const mediaStream = await window.navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: this.state.__audioinputCurrent
            ? ({
                exact: this.state.__audioinputCurrent,
              } as ConstrainDOMString)
            : undefined,
        },
        video: {
          deviceId: this.state.__videoinputCurrent
            ? ({
                exact: this.state.__videoinputCurrent,
              } as ConstrainDOMString)
            : undefined,
        },
      })

      const audioMediaStreamTrack = mediaStream.getAudioTracks()[0]
      const videoMediaStreamTrack = mediaStream.getVideoTracks()[0]
      let localAudio = null
      let localVideo = null
      if (videoMediaStreamTrack.label.startsWith("OBS")) {
        localAudio = await AgoraRTC.createCustomAudioTrack({
          mediaStreamTrack: audioMediaStreamTrack,
        })
        localVideo = await AgoraRTC.createCustomVideoTrack({
          frameRate: 30,
          height: 1280,
          width: 400,
          mediaStreamTrack: videoMediaStreamTrack,
        })
      } else {
        localAudio = await AgoraRTC.createMicrophoneAudioTrack()
        localVideo = await AgoraRTC.createCameraVideoTrack()
      }
      this.setState(
        {selfVideo: localVideo, selfAudio: localAudio},
        async () => {
          document.getElementById("videoArea").innerHTML = ""
          localVideo.play("videoArea", {fit: "cover"})
          await this.client.setClientRole("host")
          await this.client.publish([localAudio, localVideo])

          if (
            get(this.state, "__videoAreaSelf") ||
            !get(this.props, "data.package.video_usable")
          ) {
            localVideo.setMuted(true)
          }

          if (get(this.state, "__isMuteAudio")) {
            localAudio.setMuted(true)
          }
        }
      )
    } catch (_) {
      if (
        Cookies.get(AUDIO_DEVICE_ID_KEY) ||
        Cookies.get(VIDEO_DEVICE_ID_KEY)
      ) {
        // A camera or microphone failed to start.
        // It can be because the device is unavailable or removed.
        // We then make an attempt to reset to default device ids.
        Cookies.remove(AUDIO_DEVICE_ID_KEY)
        Cookies.remove(VIDEO_DEVICE_ID_KEY)
        window.location.reload()
      }
    }
  }

  componentDidMount = async () => {
    if (this.props.data.is_purchaser) {
      this.pollerBuyer()
    } else {
      const res = await axios.get(
        this.props.data?.active_spot_lane_packages_package_path
      )

      const _$$endAt = moment(res?.data?.end_at * 1000)

      this.setState(
        {
          __activeSpot: res?.data,
          __$$callStartAt: moment(res?.data?.start_at * 1000),
          __$$callEndAt: this.$$endAt.isBefore(_$$endAt)
            ? this.$$endAt
            : _$$endAt,
        },
        () => {
          this.pollerPublisher()
        }
      )
    }

    this.setHeight()
    window.addEventListener("resize", this.onResize)

    try {
      // Safari requires `navigator.mediaDevices.getUserMedia` too be called once with default credentials
      // in order for `navigator.mediaDevices.enumerateDevices` to work.
      //
      // https://github.com/webrtcHacks/WebRTC-Camera-Resolution/blob/master/js/resolutionScan.js
      // https://github.com/samdutton/simpl/blob/gh-pages/getusermedia/sources/js/main.js
      // https://docs.agora.io/en/Video/custom_video_web?platform=Web
      await window.navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      })
    } finally {
      // While a user has another app accessing a camera, i.e. they use FaceRig,
      // `getUserMedia()` will throw a DOMException error.
      // But it doesn't matter, it's safe to proceed to `enumerateDevices()` anyway.
      const mediaDevices =
        await window.navigator.mediaDevices.enumerateDevices()

      const videoinputs = filter(mediaDevices, (x) => x?.kind == "videoinput")
      const audioinputs = filter(mediaDevices, (x) => x?.kind == "audioinput")

      this.setState(
        (prevState) => ({
          __videoinputs: videoinputs,
          __videoinputCurrent: prevState?.__videoinputCurrent
            ? prevState?.__videoinputCurrent
            : videoinputs?.[0]?.deviceId,
          __audioinputs: audioinputs,
          __audioinputCurrent: prevState?.__audioinputCurrent
            ? prevState?.__audioinputCurrent
            : audioinputs?.[0]?.deviceId,
        }),
        async () => {
          const {appId, channelKey, token, uid} = get(this.props, "data")
          try {
            const uid1 = await this.client.join(
              appId,
              channelKey,
              token,
              Number(uid)
            )
            console.log("join success")

            if (!this.props?.data.is_purchaser) {
              this.setState(
                {
                  __calling: true,
                },
                () => {
                  // If a publisher joins, play a publisher stream right away.
                  this.playSelfStream()
                }
              )
            }
          } catch (e) {
            console.log("join failed", e)
          }

          this.client.on("user-published", async (remoteUser, mediaType) => {
            const stream = await this.client.subscribe(remoteUser, mediaType)
            if (mediaType == "video") {
              console.log("subscribe video success")
              remoteUser.videoTrack.play(this.state.__videoAreaPartner)
            }
            if (mediaType == "audio") {
              console.log("subscribe audio success")
              remoteUser.audioTrack.play()
            }
            this.setState(
              {
                partnerVideo: remoteUser.videoTrack,
                partnerAudio: remoteUser.audioTrack,
                __calling: true,
              },
              async () => {
                stream.play(get(this.state, "__videoAreaPartner"))

                if (this.props.data?.is_purchaser) {
                  const _$$now = moment(
                    this.state.__queue?.call_start
                      ? this.state.__queue?.call_start * 1000
                      : undefined
                  )

                  const _$$endAt = moment(_$$now).add(
                    this.state.__queue?.duration,
                    "seconds"
                  )

                  this.setState(
                    {
                      __$$callStartAt: _$$now,
                      __$$callEndAt: this.$$endAt.isBefore(_$$endAt)
                        ? this.$$endAt
                        : _$$endAt,
                    },
                    () => {
                      if (
                        this.state.selfVideo &&
                        (this.state.selfVideo as any)?.isPlaying()
                      ) {
                        return
                      }

                      this.playSelfStream()
                    }
                  )
                }
              }
            )
          })

          this.client.on("user-unpublished", async (remoteUser, mediaType) => {
            const stream = await this.client.unsubscribe(remoteUser, mediaType)
            if (stream) {
              stream.stop()
            }
          })

          this.client.on("user-info-updated", async (uid, msg) => {
            if (msg === "mute-video") {
              this.setState({
                __isMuteVideoPartner: true,
              })
            }
            if (msg === "unmute-video") {
              this.setState({
                __isMuteVideoPartner: false,
              })
            }
          })
        }
      )
    }
  }

  render = () => {
    const {data} = this.props
    const {t} = data

    return (
      <div
        className="AppBookingsTalk"
        style={{
          height: this.state.__height,
        }}
      >
        <div className="kontainer-app">
          <div className="AppBookingsTalk__wrapper">
            {this.state.__calling ? (
              <React.Fragment>
                <div
                  id="videoAreaBack"
                  className={className(
                    "AppBookingsTalk__wrapper__video-area",
                    {}
                  )}
                ></div>

                <div className="AppBookingsTalk__wrapper__shadow"></div>

                {(() => {
                  let backgroundImage: string | null | undefined

                  if (
                    this.state.__videoAreaSelf == "videoAreaBack" &&
                    (this.state.__isMuteVideoSelf ||
                      !this.props.data?.package?.video_usable)
                  ) {
                    backgroundImage = `${this.props.data?.me?.icon_image_url_with_fallback}`
                  }

                  if (
                    this.state.__videoAreaPartner == "videoAreaBack" &&
                    (this.state.partnerVideo || this.state.partnerAudio) &&
                    (this.state.__isMuteVideoPartner ||
                      !this.props.data?.package?.partner_video_usable)
                  ) {
                    if (
                      this.props.data?.partner?.icon_image_url_with_fallback
                    ) {
                      backgroundImage = `${this.props.data?.partner?.icon_image_url_with_fallback}`
                    } else if (this.props.data?.spots) {
                      const _currentSpot = this.props.data?.spots?.find(
                        (x) =>
                          x?.token ==
                          this.state.__activeSpot?.active_spot?.token
                      )

                      backgroundImage =
                        _currentSpot?.user?.icon_image_url_with_fallback
                    }
                  }

                  if (!backgroundImage) {
                    return null
                  }

                  return (
                    <div className="AppBookingsTalk__wrapper__placeholder">
                      <img
                        className="AppBookingsTalk__wrapper__placeholder__img"
                        src={backgroundImage}
                      />
                    </div>
                  )
                })()}

                {!this.state.partnerVideo &&
                  !this.state.partnerAudio &&
                  this.state.__videoAreaPartner == "videoAreaBack" && (
                    <p className="AppBookingsTalk__wrapper__waiting">
                      {tt(t, "current.waiting_partner")}
                    </p>
                  )}

                <div className="AppBookingsTalk__top">
                  <div className="AppBookingsTalk__top__partner"></div>

                  {this.state.__$$callStartAt && (
                    <DialCountdown
                      $$startAt={this.state.__$$callStartAt}
                      $$endAt={this.state.__$$callEndAt}
                    />
                  )}
                </div>

                <div className="AppBookingsTalk__bottom">
                  <div className="AppBookingsTalk__bottom__self">
                    <div
                      id="videoAreaFront"
                      className={className(
                        "AppBookingsTalk__bottom__self__video-area"
                      )}
                    ></div>

                    {(() => {
                      let backgroundImage: string | null | undefined

                      if (
                        this.state.__videoAreaSelf == "videoAreaFront" &&
                        (this.state.__isMuteVideoSelf ||
                          !this.props.data?.package?.video_usable)
                      ) {
                        backgroundImage = `url(${this.props.data?.me?.icon_image_url_with_fallback})`
                      }

                      if (
                        this.state.__videoAreaPartner == "videoAreaFront" &&
                        this.state.partnerVideo &&
                        (this.state.__isMuteVideoPartner ||
                          !this.props.data?.package?.partner_video_usable)
                      ) {
                        if (
                          this.props.data?.partner?.icon_image_url_with_fallback
                        ) {
                          backgroundImage = `url(${this.props.data?.partner?.icon_image_url_with_fallback})`
                        } else if (this.props.data?.spots) {
                          const _currentSpot = this.props.data?.spots?.find(
                            (x) =>
                              x?.token ==
                              this.state.__activeSpot?.active_spot?.token
                          )

                          backgroundImage = `url(${_currentSpot?.user?.icon_image_url_with_fallback})`
                        }
                      }

                      if (!backgroundImage) {
                        backgroundImage = "none"
                      }

                      return (
                        <div
                          className={className(
                            "AppBookingsTalk__bottom__self__placeholder",
                            {
                              "AppBookingsTalk__bottom__self__placeholder--active":
                                backgroundImage != "none",
                            }
                          )}
                          style={{
                            backgroundImage,
                          }}
                        ></div>
                      )
                    })()}

                    {!this.state.partnerVideo &&
                      this.state.__videoAreaPartner == "videoAreaFront" && (
                        <p className="AppBookingsTalk__bottom__self__waiting">
                          {tt(t, "current.waiting_partner")}
                        </p>
                      )}
                  </div>

                  <div className="AppBookingsTalk__bottom__buttons">
                    <a
                      className={className(
                        "AppBookingsTalk__bottom__buttons__a",
                        {
                          "AppBookingsTalk__bottom__buttons__a--swap": true,
                          "AppBookingsTalk__bottom__buttons__a--active":
                            this.state.__videoAreaSelf == "videoAreaBack",
                        }
                      )}
                      onClick={() => {
                        const selfVideo = get(this.state, "selfVideo") as any

                        if (selfVideo) {
                          selfVideo.stop()
                        }

                        const partnerVideo = this.state.partnerVideo

                        if (partnerVideo) {
                          partnerVideo.stop()
                        }

                        const _videoAreaSelf = this.state.__videoAreaSelf
                        const _videoAreaPartner = this.state.__videoAreaPartner

                        this.setState(
                          {
                            __videoAreaSelf: _videoAreaPartner,
                            __videoAreaPartner: _videoAreaSelf,
                          },
                          () => {
                            if (selfVideo) {
                              selfVideo.play(this.state.__videoAreaSelf)
                            }

                            if (partnerVideo) {
                              partnerVideo.play(this.state.__videoAreaPartner)
                            }
                          }
                        )
                      }}
                    />

                    <a
                      className={className(
                        "AppBookingsTalk__bottom__buttons__a",
                        {
                          "AppBookingsTalk__bottom__buttons__a--audio": true,
                          "AppBookingsTalk__bottom__buttons__a--active":
                            this.state?.__isMuteAudio,
                        }
                      )}
                      onClick={() => {
                        this.setState(
                          (prevState) => ({
                            __isMuteAudio: !prevState.__isMuteAudio,
                          }),
                          () => {
                            const selfAudio = get(
                              this.state,
                              "selfAudio"
                            ) as any

                            if (selfAudio) {
                              if (get(this.state, "isMuteAudio")) {
                                Cookies.set(AUDIO_MUTE_KEY, "1")
                                selfAudio.setMuted(true)
                              } else {
                                Cookies.remove(AUDIO_MUTE_KEY)
                                selfAudio.setMuted(false)
                              }
                            }
                          }
                        )
                      }}
                    />

                    <a
                      className={className(
                        "AppBookingsTalk__bottom__buttons__a",
                        {
                          "AppBookingsTalk__bottom__buttons__a--video": true,
                          "AppBookingsTalk__bottom__buttons__a--active":
                            this.state.__isMuteVideoSelf,
                          "AppBookingsTalk__bottom__buttons__a--disabled":
                            !this.props.data?.package?.video_usable,
                        }
                      )}
                      onClick={() => {
                        this.setState(
                          (prevState) => ({
                            __isMuteVideoSelf: !prevState.__isMuteVideoSelf,
                          }),
                          () => {
                            const selfVideo = get(
                              this.state,
                              "selfVideo"
                            ) as any

                            if (selfVideo) {
                              if (get(this.state, "isMuteVideoSelf")) {
                                Cookies.set(VIDEO_MUTE_KEY, "1")
                                selfVideo.setMuted(true)
                              } else {
                                Cookies.remove(VIDEO_MUTE_KEY)
                                selfVideo.setMuted(false)
                              }
                            }
                          }
                        )
                      }}
                    />

                    <a
                      className="AppBookingsTalk__bottom__buttons__a"
                      onClick={() => {
                        this.setState({
                          __isActivePanel: true,
                        })
                      }}
                    />

                    <a
                      className={className(
                        "AppBookingsTalk__bottom__buttons__a",
                        {
                          "AppBookingsTalk__bottom__buttons__a--call-end": true,
                          "AppBookingsTalk__bottom__buttons__a--active": true,
                        }
                      )}
                      href={this.props.data?.bookings_path}
                    />
                  </div>
                </div>

                {this.state.partnerVideo && this.state.__overlayed && (
                  <a
                    className="AppBookingsTalk__overlay"
                    onClick={() => {
                      const partnerVideo: any = this.state.partnerVideo

                      // This is to fix a mute problem in mobile browsers.
                      // A user has to interact, i.e. click/touch, the page
                      // in order to enable audio.
                      // See also: https://docs.agora.io/en/Voice/autoplay_policy_web?platform=Web.
                      partnerVideo?.stop()

                      partnerVideo?.play(this.state.__videoAreaPartner, {
                        muted: false,
                      })

                      this.setState({
                        __overlayed: false,
                      })
                    }}
                  >
                    Click to start
                  </a>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.props.data?.is_purchaser && (
                  <div className="AppBookingsTalk__lane">
                    <div className="AppBookingsTalk__lane__wrapper">
                      {this.state.__callEnded ? (
                        <React.Fragment>
                          <h3 className="AppBookingsTalk__lane__h3">
                            {tt(t, "bookings_talk_lane.thanks_msg.h3")}
                          </h3>

                          <p className="AppBookingsTalk__lane__p">
                            {tt(t, "bookings_talk_lane.thanks_msg.p")}
                          </p>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {!isNil(this.state.__queue?.queue) ? (
                            <React.Fragment>
                              {this.state.__queue?.queue == null ? (
                                <p className="AppBookingsTalk__lane__h3">
                                  Loading …
                                </p>
                              ) : (
                                <React.Fragment>
                                  <h3 className="AppBookingsTalk__lane__h3">
                                    {this.state.__queue?.queue == 0 ||
                                    (this.state.__queue?.queue == 1 &&
                                      moment().isSameOrAfter(
                                        this.$$startAt
                                      )) ? (
                                      <React.Fragment>
                                        {tt(t, "bookings_talk_lane.h4_next")}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {(() => {
                                          let _count = this.state.__queue?.queue

                                          if (
                                            moment().isSameOrAfter(
                                              this.$$startAt
                                            )
                                          ) {
                                            _count = _count - 1
                                          }

                                          return (
                                            <React.Fragment>
                                              {tt(t, "bookings_talk_lane.h4", {
                                                count: _count,
                                              })}
                                            </React.Fragment>
                                          )
                                        })()}
                                      </React.Fragment>
                                    )}
                                  </h3>

                                  <Countdown
                                    $$startAt={this.$$startAt}
                                    $$endAt={this.$$endAt}
                                    t={get(t, "current.countdown")}
                                  />

                                  {!this.state.__disableExitButton && (
                                    <React.Fragment>
                                      <p
                                        className="AppBookingsTalk__lane__p"
                                        dangerouslySetInnerHTML={{
                                          __html: tt(
                                            t,
                                            "bookings_talk_lane.exit.p",
                                            {
                                              time: moment(
                                                this.state.__queue
                                                  ?.exitable_time
                                                  ? this.state.__queue
                                                      ?.exitable_time * 1000
                                                  : undefined
                                              ).format("HH:mm"),
                                            }
                                          ),
                                        }}
                                      />

                                      <a
                                        className="Button"
                                        onClick={async () => {
                                          await axios.delete(
                                            this.props.data
                                              ?.lane_packages_spot_exit_path
                                          )

                                          window.location.href =
                                            this.props.data?.bookings_path
                                        }}
                                      >
                                        {tt(t, "bookings_talk_lane.exit.a")}
                                      </a>
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <h3 className="AppBookingsTalk__lane__h3">
                                {tt(t, "bookings_talk_lane.waiting_please")}
                              </h3>

                              <Countdown
                                $$startAt={this.$$startAt}
                                $$endAt={this.$$endAt}
                              />
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}

            <div
              className={className("AppBookingsTalk__panel", {
                "AppBookingsTalk__panel--active": this.state.__isActivePanel,
              })}
            >
              <div className="AppBookingsTalk__panel__header">
                <a
                  className="AppBookingsTalk__panel__header__a"
                  onClick={() => {
                    this.setState({
                      __isActivePanel: false,
                    })
                  }}
                />

                <p className="AppBookingsTalk__panel__header__p">
                  {tt(t, "current.call_settings")}
                </p>
              </div>

              {(() => {
                let _data = [
                  {
                    h4: tt(t, "current.audio_h4"),
                    items: [
                      {
                        dt: tt(t, "current.audio"),
                        dd: find(this.state.__audioinputs, (x) => {
                          return x?.deviceId == this.state.__audioinputCurrent
                        })?.label,
                        onClick: () => {
                          this.setState({
                            __isActivePanel: false,
                            __isActivePanelAudio: true,
                          })
                        },
                      },
                    ],
                  },
                ]

                if (this.props.data?.package?.video_usable) {
                  _data = [
                    ..._data,
                    {
                      h4: tt(t, "current.video_h4"),
                      items: [
                        {
                          dt: tt(t, "t.current.video"),
                          dd: find(this.state?.__videoinputs, (x) => {
                            return (
                              x?.deviceId == this.state?.__videoinputCurrent
                            )
                          })?.label,
                          onClick: () => {
                            this.setState({
                              __isActivePanel: false,
                              __isActivePanelVideo: true,
                            })
                          },
                        },
                      ],
                    },
                  ]
                }

                return (
                  <dl className="AppBookingsTalk__panel__dl">
                    {_data.map((x, i) => (
                      <div className="AppBookingsTalk__panel__dl__item" key={i}>
                        <h4 className="AppBookingsTalk__panel__dl__item__h4">
                          {x?.h4}
                        </h4>

                        {x?.items?.map((y, j) => (
                          <a
                            key={j}
                            className="AppBookingsTalk__panel__dl__item__a"
                            onClick={() => {
                              if (y.onClick) {
                                y.onClick()
                              }
                            }}
                          >
                            <dt className="AppBookingsTalk__panel__dl__item__dt">
                              {y?.dt}
                            </dt>

                            {y?.dd && (
                              <dd className="AppBookingsTalk__panel__dl__item__dd">
                                <span className="AppBookingsTalk__panel__dl__item__dd__span">
                                  {y?.dd}
                                </span>
                              </dd>
                            )}
                          </a>
                        ))}
                      </div>
                    ))}
                  </dl>
                )
              })()}
            </div>

            {[
              {
                p: tt(t, "current.audio"),
                keyIsActivePanel: "__isActivePanelAudio",
                keyInputs: "__audioinputs",
                keyInputCurrent: "__audioinputCurrent",
                keyCookie: AUDIO_DEVICE_ID_KEY,
              },
              {
                p: tt(t, "current.video"),
                keyIsActivePanel: "__isActivePanelVideo",
                keyInputs: "__videoinputs",
                keyInputCurrent: "__videoinputCurrent",
                keyCookie: VIDEO_DEVICE_ID_KEY,
              },
            ].map((x, i) => (
              <div
                key={i}
                className={className("AppBookingsTalk__panel", {
                  "AppBookingsTalk__panel--active": get(
                    this.state,
                    x?.keyIsActivePanel
                  ),
                })}
              >
                <div className="AppBookingsTalk__panel__header">
                  <a
                    className="AppBookingsTalk__panel__header__a AppBookingsTalk__panel__header__a--back"
                    onClick={() => {
                      // @ts-ignore
                      this.setState({
                        __isActivePanel: true,
                        [x?.keyIsActivePanel]: false,
                      })
                    }}
                  />

                  <p className="AppBookingsTalk__panel__header__p">{x?.p}</p>
                </div>

                <div className="AppBookingsTalk__panel__list">
                  {(get(this.state, x?.keyInputs) || []).map(
                    (y: any, j: any) => (
                      <a
                        className={className(
                          "AppBookingsTalk__panel__list__item",
                          {
                            "AppBookingsTalk__panel__list__item--active":
                              y?.deviceId ==
                              get(this.state, x?.keyInputCurrent),
                          }
                        )}
                        key={j}
                        onClick={() => {
                          const selfVideo = this.state.selfVideo

                          if (selfVideo) {
                            selfVideo.stop()
                            this.client.unpublish(selfVideo)
                          }

                          this.setState(
                            // @ts-ignore
                            {
                              [x?.keyInputCurrent]: y?.deviceId,
                            },
                            () => {
                              Cookies.set(x?.keyCookie, y?.deviceId)

                              window.setTimeout(() => {
                                this.selfVideo()
                              }, 1000)
                            }
                          )
                        }}
                      >
                        <dt className="AppBookingsTalk__panel__list__item__dt">
                          {y?.label}
                        </dt>
                      </a>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default AppLanePackagesSpotsTalk
